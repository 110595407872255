import React, { Component } from "react"
import Navigation from "../Start/modules/Navigation";
import Content from "./modules/Content"
import Footer from "../Start/modules/Footer"
import Index from "./modules/Content";
import Header from "../Start/modules/SmallHeader";


export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Aktuelle Blogposts" subTitle="Nachrichten. Case Studies. Updates." image="/img/blog.jpg" />
            <Index /> {/* This index is take from AllBlogPage */}
            <Footer />
        </>
    }
}
