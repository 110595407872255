import React, { useEffect, useState } from "react"
import StyledSubItem from "./styled/StyledSubItem"
import StyledNavigation from "./styled/StyledNavigation"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import StyledMobileMenu from "./styled/StyledMobileMenu";

const Underline = () => (
    <motion.div
        className="underline"
        layoutId="underline"
        layout
    ></motion.div>
);

const MenuItemVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
        }
    },
}

const SubItemVariants = {
    hidden: {
        x: -40,
        opacity: 0
    },
    visible: {
        x: 0,
        opacity: 1
    }
}

const MenuItem = ({ text, children, ...props }) => {
    const [isBeingHovered, setIsBeingHovered] = useState(false)

    return <Link to={props.dropdown ? null : props.to}>
        <motion.div
            className="li"
            onHoverStart={() => setIsBeingHovered(true)}
            onHoverEnd={() => setIsBeingHovered(false)}
        >
      <span className="relative">
        {props.title}{props.dropdown && <i className="fas fa-chevron-down" />}
          {isBeingHovered && <Underline />}
      </span>
            {isBeingHovered && props.dropdown && (
                <div className="subItemsMenu">
                    <motion.div
                        {...props}
                        layoutId="menu1"
                        className="subItemsTop"
                        variants={{
                            visible: {
                                x: 0
                            }
                        }}
                        style={{ minWidth: 400 }}
                        initial="hidden"
                        animate="visible"
                    >
                        <motion.div
                            {...props}
                            layoutId="menu"
                            className="subItems"
                            variants={MenuItemVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            {children}
                        </motion.div>
                    </motion.div>
                </div>
            )}
        </motion.div>
    </Link>;
};



const SubItem = ({ title, text, icon, to, href }) => {
    return <motion.div
        className="my-2 group cursor-pointer min-w-max"
        layout
        variants={SubItemVariants}>
        <Link to={to} onClick={() => {
            if(href) window.open(href, "_blank")
        }}>
            <StyledSubItem>
                <i className={`fas fa-${icon}`} />
                <div>
                    <p className="title">
                        {title}
                    </p>
                    <p className="text">
                        {text}
                    </p>
                </div>
            </StyledSubItem>
        </Link>
    </motion.div>
}

export default function() {
    const [ menuOpen, setMenuOpen ] = useState(false)

    useEffect(() => {
        const widget = document.querySelector("#trengo-web-widget")

        if (menuOpen) {
            document.body.style.overflow = 'hidden';
            if(widget) widget.style.display = "none"
        } else {
            document.body.style.overflow = 'unset';
            if(widget) widget.style.display = "block"
        }
    }, [menuOpen]);

    const [ offset, setOffset ] = useState(0)

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset)

        window.removeEventListener("scroll", onScroll)
        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)
    })


    return <StyledNavigation offset={offset > 0}>
        <div className="container">
            <StyledMobileMenu onClick={() => setMenuOpen(false)} style={{ visibility: menuOpen ? "visible" : "hidden", opacity: menuOpen ? 1 : 0 }}>
                <div className="menu" onClick={e => e.stopPropagation()} style={{ transform: menuOpen ? "translateX(0)" : "translateX(100%)" }}>
                    <Link to="/">Startseite</Link>
                    <div className="submenu">
                        <div className="title">Dienstleistungen</div>
                        <Link to="/services/maintenance">
                            <div className="left">
                                <i className="fa-duotone fa-cog" />
                            </div>
                            <div className="right">
                                <p className="title">Technische Anlagenwartung</p>
                                <p className="description">Wartung, Service und Reparaturen bundesweit.</p>
                            </div>
                        </Link>
                        <Link to="/services/construction">
                            <div className="left">
                                <i className="fa-duotone fa-person-digging" />
                            </div>
                            <div className="right">
                                <p className="title">Umbauten und Sanierungen</p>
                                <p className="description">Bauprojekte, Renovierungen und Sanierungen aller Art.</p>
                            </div>
                        </Link>
                        <Link to="/services/installation">
                            <div className="left">
                                <i className="fa-duotone fa-wrench" />
                            </div>
                            <div className="right">
                                <p className="title">Anlageninstallation</p>
                                <p className="description">Errichtung neuer Anlagen im Neubau & Bestand.</p>
                            </div>
                        </Link>
                        <Link to="/services/digital">
                            <div className="left">
                                <i className="fa-duotone fa-qrcode" />
                            </div>
                            <div className="right">
                                <p className="title">Digitale Gebäudeaufnahme</p>
                                <p className="description">Inventarisierung der TGA in Digitalformat.</p>
                            </div>
                        </Link>
                        <Link to="/services/networking">
                            <div className="left">
                                <i className="fa-duotone fa-chart-network" />
                            </div>
                            <div className="right">
                                <p className="title">Dienstleister Networking</p>
                                <p className="description">Vermittlung qualifizierter Service-Dienstleister.</p>
                            </div>
                        </Link>
                        <Link to="/services/fullservice">
                            <div className="left">
                                <i className="fa-duotone fa-hand-holding-heart" />
                            </div>
                            <div className="right">
                                <p className="title">Full-Service-Paket</p>
                                <p className="description">Rundumpaket für eine vollständige FM-Betreuung.</p>
                            </div>
                        </Link>
                    </div>
                    <div className="submenu">
                        <div className="title">Software</div>
                        <a href="https://betreiber.scanmetrix.io">
                            <div className="left">
                                <i className="fa-duotone fa-building" />
                            </div>
                            <div className="right">
                                <p className="title">Für Betreiber</p>
                                <p className="description">Cloud CAFM für Filialisten, Handelsketten und FM-Dienstleister.</p>
                            </div>
                        </a>
                        <a href="https://handwerk.scanmetrix.io">
                            <div className="left">
                                <i className="fa-duotone fa-wrench" />
                            </div>
                            <div className="right">
                                <p className="title">Für Handwerker</p>
                                <p className="description">Cloud ERP für Service-Dienstleister & Handwerker.</p>
                            </div>
                        </a>
                    </div>
                    <div className="submenu">
                        <div className="title">Unternehmen</div>
                        <Link to="/about">
                            <div className="left">
                                <i className="fa-duotone fa-circle-info" />
                            </div>
                            <div className="right">
                                <p className="title">Über uns</p>
                                <p className="description">Alles, was uns auszeichnet.</p>
                            </div>
                        </Link>
                        <Link to="/careers">
                            <div className="left">
                                <i className="fa-duotone fa-user-tie" />
                            </div>
                            <div className="right">
                                <p className="title">Karriere</p>
                                <p className="description">Offene Stellen- und Jobanzeigen.</p>
                            </div>
                        </Link>
                        <Link to="/media">
                            <div className="left">
                                <i className="fa-duotone fa-newspaper" />
                            </div>
                            <div className="right">
                                <p className="title">Medien & Downloads</p>
                                <p className="description">Präsentationen, Flyer und Infos.</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </StyledMobileMenu>
            <Link to={"/"} className="logoContainer"><img src="https://betreiber.scanmetrix.io/logo_text_white.svg" className="logo" /></Link>
            <div className="hamburger" onClick={() => setMenuOpen(true)}>
                <i className="far fa-bars" />
            </div>
            <ul>
                <motion.div className="border p-10 flex justify-center">
                    <MenuItem title="Startseite" to="/" />
                    <MenuItem title="Dienstleistungen" dropdown>
                        <SubItem
                            to="/services/maintenance"
                            icon="cog"
                            title="Technische Anlagenwartung"
                            text="Wartung, Service und Reparaturen bundesweit."
                        />
                        <SubItem
                            to="/services/construction"
                            icon="person-digging"
                            title="Umbauten und Sanierungen"
                            text="Bauprojekte, Renovierungen und Sanierungen aller Art."
                        />
                        <SubItem
                            to="/services/installation"
                            icon="wrench"
                            title="Anlageninstallation"
                            text="Errichtung neuer Anlagen im Neubau & Bestand."
                        />
                        <SubItem
                            to="/services/digital"
                            icon="qrcode"
                            title="Digitale Gebäudeaufnahme"
                            text="Inventarisierung der TGA in Digitalformat."
                        />
                        <SubItem
                            to="/services/networking"
                            icon="chart-network"
                            title="Dienstleister Networking"
                            text="Vermittlung qualifizierter Service-Dienstleister."
                        />
                        <SubItem
                            to="/services/fullservice"
                            icon="hand-holding-heart"
                            title="Full-Service-Paket"
                            text="Rundumpaket für eine vollständige FM-Betreuung."
                        />
                    </MenuItem>
                    <MenuItem title="Software" dropdown>
                        {/*<SubItem
                        icon="cloud"
                        title="Die Plattform"
                        text="Die digitale Gesamtplattform im FM-Umfeld."
                    />*/}
                        <SubItem
                            href="https://betreiber.scanmetrix.io/"
                            icon="building"
                            title="Für Betreiber"
                            text="Cloud CAFM für Filialisten, Handelsketten und FM-Dienstleister."
                        />
                        <SubItem
                            href="https://handwerk.scanmetrix.io/"
                            icon="wrench"
                            title="Für Handwerker"
                            text="Cloud ERP für Service-Dienstleister & Handwerker."
                        />
                    </MenuItem>
                    <MenuItem title="Unternehmen" dropdown>
                        <SubItem
                            to="/about"
                            icon="circle-info"
                            title="Über uns"
                            text="Alles, was uns auszeichnet."
                        />
                        <SubItem
                            to="/careers"
                            icon="user-tie"
                            title="Karriere"
                            text="Offene Stellen- und Jobanzeigen."
                        />
                        <SubItem
                            to="/media"
                            icon="newspaper"
                            title="Medien & Downloads"
                            text="Präsentationen, Flyer und Infos."
                        />
                        {/*<SubItem
                            to="/blog"
                            icon="blog"
                            title="Blog"
                            text="Unser Nachrichtenjournal."
                        />*/}
                    </MenuItem>
                    {/* <MenuItem title="Erfolgsgeschichten" to="/successstories" /> */}
                    <MenuItem title="Kontakt" to="/#contact" />
                </motion.div>
            </ul>
        </div>
    </StyledNavigation>
}
