import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"

import { Link } from "react-router-dom"

const Text = styled.div`
    font-size: 22px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Dienstleister Networking" subTitle="Full-Service-Paket" image="/img/networking.jpg" />
            <Content>
                <div className="content">
                    <h3>Von nah bis fern: Ihre Gewerke, unsere Partner.</h3>
                    <h2>Unsere Expertise erstreckt sich sowohl regional als auch bundesweit, um Ihnen die passenden Dienstleister für Ihre Gewerke zur Seite zu stellen. Mithilfe unseres etablierten Dienstleisternetzwerks identifizieren wir den idealen Partner, der Ihren Anforderungen gerecht wird. Dabei verlassen wir uns auf langjährige, bewährte Unternehmen, mit denen wir persönlich Erfahrungen gesammelt haben. Vertrauen Sie auf unser starkes Netzwerk, um in kürzester Zeit zum passenden Partner zu finden.</h2>
                   
                </div>
            </Content>
            <Footer />
        </>
    }
}
