import React, {Component, useEffect} from "react"
import Navigation from "../Start/modules/Navigation";
import Header from "../Start/modules/SmallHeader"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import {Navigate, useLocation} from "react-router-dom";

export default function() {
    const { pathname, hash, key } = useLocation()

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView()
                }
            }, 0)
        }
    }, [pathname, hash, key])

    return <>
        <Navigation />
        <Header title="Impressum" subTitle="Gemäß §5 TMG" image="/img/imprint.jpg" />
        <Content>
            <div className="content">
                <div className="grid">
                    <div className="item1"><p><b>scanmetrix.fm GmbH</b><br />An der Pönt
                        62<br />40885 Ratingen</p><br /><p>Handelsregister: <i>HRB
                        33521</i><br />Registergericht: <i>Duisburg</i></p><br /><p>USt-IdNr: DE338383360</p><br /><p><b style={{ marginRight: 8 }}><i
                        className="far fa-user-tie"></i>Vertreten durch:</b>Udo Witte<i>, geschäftsführender
                        Gesellschafter</i></p></div>
                    <div className="item2"><h4>Kontakt</h4><p><i className="far fa-phone"></i> Telefon: 02102 56 56 930<br /><i className="far fa-envelope"></i> E-Mail: info@scanmetrix.fm</p><h4>Verantwortlich
                        für den Inhalt nach § 55 Abs. 2 RStV</h4>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", gridGap: 32}}><p><b>Udo Witte</b><br />Geschäftsführer<br />An der Pönt 62<br />40885 Ratingen</p></div>
                    </div>
                </div>
                <h4>EU-Streitschlichtung</h4><p>Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank"
                                                       rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br /> Unsere
                    E-Mail-Adresse finden Sie oben im Impressum.</p>
                <h4>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h4><p>Wir sind nicht
                bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                teilzunehmen.</p><h3 id="disclaimer" style={{ marginTop: 48 }}>Haftung für Inhalte</h3> <p>Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG
                für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10
                TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                hinweisen.</p> <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
                den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
                dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p> <h3 style={{ marginTop: 48 }}>Haftung für
                Links</h3> <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen
                Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                erkennbar.</p> <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p> <h3 style={{ marginTop: 48 }}>Urheberrecht</h3> <p>Die
                durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                Gebrauch gestattet.</p> <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
                werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir
                um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                Inhalte umgehend entfernen.</p></div>
        </Content>
        <Footer />
    </>
}
