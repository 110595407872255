import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 128px 0;
  font-size: 20px;
  text-align: center;
  
  a {
      text-decoration: none;
      color: #3b97d3;
      font-weight: 600;
  }
  
  .container {
    width: 100%;
    box-sizing: border-box;
    max-width: 1500px;
    position: relative;
    padding: 0 64px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
    li {
        padding-left: 16px;
        margin: 8px 0;
        margin-left: 16px;
    }

    ul {
        margin: 16px 0;
    }

    h2 {
      font-size: 24px;
      line-height: 38px;
      margin-bottom: 32px;
      font-weight: 400;
    }

    h3 {
      font-weight: 900;
      font-size: 44px;
      color: #3b97d3;
      user-select: none;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      user-select: none;
      margin: 16px 0;
    }
    
    h5 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 20px;
    }
    
    h6 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 18px;
    }



    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      user-select: none;
      grid-gap: 64px;
      margin-top:64px;
      
    
      @media screen and (max-width: 1024px) {
          grid-template-columns: 1fr;
      }
        
  
    }

    .grid2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      user-select: none;
      grid-gap: 64px;
      margin-top:64px;
      
    
      @media screen and (max-width: 1024px) {
          grid-template-columns: 1fr;
      }
    }

    
      .item {
          display: flex;
          align-items: center;
          
          flex-direction: column;
        
          i {
              width: 64px;
              height: 64px;
              border: 3px solid #3b97d3;
              color: #3b97d3;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 28px;
              flex-shrink: 0;
              margin-bottom: 24px;
              
          }
        
          .right {
              display: flex;
              flex-direction: column;
              width: 100%;
            
              p.title {
                  font-weight: bold;
                  font-size: 25px;
                  text-transform: uppercase;
                  color: #20242b;
                  width: 100%;
                  
                   
              }
            
              p.description {
                  font-size: 20px;
  
                  margin-top: 8px;
                  line-height: 32px;
                  width: 100%;
              }
          }
      }
  } 
  }
`
