import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 96px 0;
  
  


  .newContainer{
    
    width: 100%;
    height: 100%;
    
    
    
    
  }

  
  h2 {
    font-size: 48px;
    color: #20242b;
  }

  h3 {
    font-weight: 900;
    font-size: 24px;
    color: #3b97d3;
  }
  

  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 32px;
    max-width: 1500px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    //justify-content: center;
    //border: 1px solid red;
    opacity: 1;
    white-space: nowrap;
    
    @media screen and (max-width: 1600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }


    @media screen and (max-width: 1400px) {
      grid-template-columns: 1fr 1fr 1fr;
    }


    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    div{
       //border: 1px solid green;
         display: flex;
         justify-content: center;
         width: 100%;
   }

   .innerContainer{
        display: flex;
        flex-direction: column;
        
    
    


   }

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
     .title {
      text-align: center;
      user-select: none;
      margin-bottom: 64px;
      max-height: 10px;
      
      

      h4 {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        
      }
    }
    
    .logos {
      //border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      //margin-left: -40px;
      grid-gap: 10px;
      

     
      
    }
    
    
    
    img {
      //border: 1px solid black;
      width: 80%;
      height: 70px;
      filter: grayscale(100%);
      opacity: 0.45;
      //-webkit-user-drag: none;
      user-select: none;
      transition: filter 0.3s, opacity 0.3s;  
      object-fit: contain;
      margin: 10px;
      -moz-user-select: none;
      -webkit-user-select: none;
       user-select: none;

      
      
      
      @media screen and (max-width: 1100px) {
          //min-width: 170px;
          //margin: 32px auto;
          //padding: 0 16px;
      }


      &:hover {
          filter: none;
          opacity: 1;
      }
    }
  }




  .buttonStyled{
    //width: 64px;
    //height: 64px;
    padding: 12px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b97d3;
    font-size: 24px;
    transition: all 0.3s;
    //top: calc(50% - 32px);
    border: none;
    background: transparent;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
  }
  }
`
