import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Anlageninstallation" subTitle="Errichtung und Einbau neuer TGA." image="/img/installation.jpg" />
            <Content>
                <div className="content">
                    <h3>Nachhaltige TGA-Anlagen für Ihre Gebäude.</h3>
                    <h2>Bei scanmetrix erhalten Sie eine professionelle und normgerechte Installation verschiedenster Anlagen, von Löschanlagen bis hin zu Tageslichtsystemen. Unsere Experten sorgen dafür, dass die Installationen den höchsten Sicherheitsstandards entsprechen. Dabei legen wir besonderen Wert auf saubere und präzise Ausführung, um beste Ergebnisse zu erzielen.</h2>
                    <div className="grid">
                        <div className="item">
                            <i className="fa-solid fa-meter-bolt" />
                            <div className="right">
                                <p className="title">Energiemanagement</p>
                                <p className="description">Wir setzen auf effizientes Energiemanagement bereits bei der Anlageninstallation, um nachhaltige und kostenbewusste Lösungen zu bieten.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-regular fa-sprinkler-ceiling" />
                            <div className="right">
                                <p className="title">Errichtung von Löschanlagen</p>
                                <p className="description">Wir bieten die fachgerechte Errichtung von Löschanlagen wie Novec 1230, FM 200 und CO², um effektiven Brandschutz zu gewährleisten.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-light fa-sensor-on" />
                            <div className="right">
                                <p className="title">Errichtung von RWA-Anlagen</p>
                                <p className="description">Unser Team sorgt für die professionelle Errichtung von RWA-Anlagen gemäß den Standards der DIN 18232, um eine zuverlässige Rauch- und Wärmeabzugsfunktion zu gewährleisten.</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid2">
                        <div className="item">
                            <i className="fa-solid fa-light-ceiling" />
                            <div className="right">
                                <p className="title">Errichtung von Tageslichtsystemen</p>
                                <p className="description">Bei der Errichtung von Tageslichtsystemen halten wir uns streng an die Richtlinien der DIN 18234, um eine optimale Nutzung natürlichen Lichts in Ihren Räumlichkeiten zu gewährleisten.</p>
                            </div>
                        </div>
                        <div className="item">
                            <i className="fa-solid fa-door-closed" />
                            <div className="right">
                                <p className="title">Einbau von Feuerschutzabschlüssen</p>
                                <p className="description">Unser qualifiziertes Team übernimmt den fachgerechten Einbau von Feuerschutzabschlüssen, um Ihre Gebäude und Räumlichkeiten bestmöglich vor Brandausbreitung zu schützen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <Footer />
        </>
    }
}
