import styled from "styled-components"

export default styled.div`
    display: flex;
    align-items: center;
    color: #20242b;
    cursor: pointer;
    transition: background 0.3s;
    padding: 16px;
    border-radius: 5px;
    
    &:hover {
        background: #eff2f7;
      
        i {
            background: #3b97d3;
            color: white;
        }
    }
  
    .title {
        font-weight: 900;
        font-size: 18px;
    }
  
    .text {
        font-weight: 500;
        font-size: 18px;
    }
  
    i {
        margin-right: 16px;
        flex-shrink: 0;
        background: rgba(59, 151, 211, 0.25);
        font-size: 20px !important;
        color: #3b97d3;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transition: all 0.3s;
    }
`

// style={{ position: "sticky", zIndex: "1500", top: "0" }}
