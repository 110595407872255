import React, { Component } from "react"
import Header from "../Start/modules/SmallHeader"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import Navigation from "../Start/modules/Navigation"

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Allgemeine Geschäftsbedingungen" subTitle="Stand Februar 2023" image="/img/agb.jpg" />
            <Content>
                <div className="content"><h2>Bedingungen für die Durchführung von Lieferungen und Leistungen der
                    scanmetrix.fm GmbH (Stand 17.02.2023)</h2><h3>A. Allgemeine Bestimmungen</h3><h4>1. Geltungsbereich
                    / Ausschluss fremder AGB</h4><p>Die nachstehenden Bedingungen gelten auch ohne ausdrückliche
                    Vereinbarung für alle – auch künftigen – Lieferungen, Leistungen und Angebote der <i>scanmetrix.fm</i> GmbH
                    ("<i>scanmetrix.fm</i>"), soweit nicht abweichende Bedingungen ausdrücklich vereinbart sind.</p>
                    <p>Allgemeine Geschäftsbedingungen des Auftraggebers erkennt <i>scanmetrix.fm</i> nicht an, ihnen wird
                        ausdrücklich widersprochen.</p><p>Diese Bedingungen gelten auch dann, wenn <i>scanmetrix.fm</i> in
                        Kenntnis entgegenstehender oder abweichender Bedingungen des Auftraggebers die vertraglichen
                        Pflichten vorbehaltlos erfüllt.</p><h4>2. Vertragsschluss</h4>
                    <ul style={{ listStyleType: "decimal" }}>
                        <li>Alle den Vertrag und seine Ausführung betreffenden Vereinbarungen zwischen <i>scanmetrix.fm</i> und
                            dem Auftraggeber bedürfen zu ihrer Wirksamkeit der Schriftform.
                        </li>
                        <li>Die Angebote von <i>scanmetrix.fm</i> und darin enthaltene Zeichnungen, Abbildungen, Maße, Gewichte
                            und sonstige Leistungsdaten sind freibleibend und unverbindlich, sofern sie nicht
                            ausdrücklich als verbindlich gekennzeichnet sind oder eine bestimmte Annahmefrist enthalten.
                            An verbindliche Angebote hält sich <i>scanmetrix.fm</i> 7 Tage ab Angebotsdatum gebunden, sofern in
                            dem Angebot keine andere Annahmefrist bestimmt ist.
                        </li>
                        <li>Ist die Bestellung des Auftraggebers ein Angebot nach § 145 Bürgerliches Gesetzbuch (BGB),
                            so kann <i>scanmetrix.fm</i> dieses Angebot innerhalb von zwei Wochen nach dessen Zugang annehmen,
                            es sei denn, der Auftraggeber hat eine andere Annahmefrist bestimmt.
                        </li>
                        <li>War das Angebot von <i>scanmetrix.fm</i> nicht als verbindlich gekennzeichnet oder die Annahmefrist
                            verstrichen, kommt ein Vertrag erst mit der schriftlichen Auftragsbestätigung von
                            <i>scanmetrix.fm</i> zustande. Sollte es im Einzelfall keine Auftragsbestätigung geben oder der
                            Vertrag ohne Auftragsbestätigung zustande kommen, ist für den Inhalt des Vertrages das
                            Angebot von <i>scanmetrix.fm</i> entscheidend. Haben Auftraggeber und <i>scanmetrix.fm</i> gemeinsam ein
                            schriftliches Dokument über die Lieferung unterzeichnet und enthält dieses Dokument
                            sämtliche Vertragsbedingungen, so steht dieses Dokument einer schriftlichen
                            Auftragsbestätigung gleich.
                        </li>
                        <li>Vertragsbestandteile und Reihenfolge Vertragsbestandteile sind (sofern nicht anders
                            vereinbart):
                            <ol>
                                <li>Die Auftragsbestätigung von <i>scanmetrix.fm</i></li>
                                <li>sofern vorhanden: der von <i>scanmetrix.fm</i> und dem Auftraggeber unterzeichnete
                                    Vertrag,
                                </li>
                                <li>das Angebot von <i>scanmetrix.fm</i>,</li>
                                <li>sofern vorhanden: die Annahmeerklärung des Auftraggebers,</li>
                                <li>diese Allgemeinen Bedingungen,</li>
                                <li>die Allgemeinen Vertragsbedingungen für Bauleistungen – DIN 1961; VOB Teil B nebst
                                    den einschlägigen Allgemeinen Technischen Vertragsbedingungen für Bauleistungen DIN
                                    18299 ff. VOB Teil C.
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <p>Bei Widersprüchen zwischen den vorgenannten Vertragsbestandteilen ergibt sich die Rangfolge der
                        Regelungen aus der vorstehenden Reihenfolge, wobei die vorrangige Regelung die nachrangige
                        Regelung auch insoweit verdrängt, als die vorrangige Regelung der ergänzenden Auslegung
                        zugänglich ist.</p><h4>3. Exportkontrolle</h4><p>Abschluss und Durchführung des Vertrags
                        („Geschäft“) stehen unter dem Vorbehalt exportkontrollrechtlicher Zulässigkeit nach anwendbarem
                        deutschen und EU-Recht; dies gilt auch in Bezug auf anwendbares US- und sonstiges nationales
                        Recht, soweit dem nicht deutsche oder europäische Rechtsvorschriften entgegenstehen.</p><p>Die
                        Parteien unterstützen sich gegenseitig, insbesondere durch Zurverfügungstellung aller
                        erforderlichen Informationen und Dokumente („Kooperationspflicht“), um etwaige
                        exportkontrollrechtliche Beschränkungen prüfen und deren Beachtung sicherstellen zu können (z.B.
                        bzgl. der Einholung behördlicher Genehmigungen/Auskünfte oder der Erfüllung von
                        Mitteilungspflichten).</p><p>Bestehen bei <i>scanmetrix.fm</i> Zweifel, ob derartige Beschränkungen
                        einschlägig sind, kann <i>scanmetrix.fm</i> verlangen, dass eine rechtssichere Stellungnahme der
                        zuständigen Exportkontrollbehörde eingeholt wird (z.B. „Nullbescheid“).</p><p>Stehen
                        exportkontrollrechtliche Beschränkungen dem Geschäft entgegen oder lassen sich Zweifel daran
                        nicht durch eine derartige Stellungnahme innerhalb einer Frist von 3 Monaten nach entsprechendem
                        Verlangen von <i>scanmetrix.fm</i> ausräumen oder kommt der Vertragspartner nach Aufforderung durch
                        <i>scanmetrix.fm</i> binnen 3 Wochen seiner Kooperationspflicht nicht nach, ist <i>scanmetrix.fm</i> zum
                        Rücktritt vom Vertrag berechtigt.</p><p>Ansprüche gegen <i>scanmetrix.fm</i> wegen Verzögerung oder
                        Nichtleistung aufgrund exportkontrollrechtlicher Beschränkungen oder der Klärung von
                        diesbezüglichen Zweifeln sind außer im Falle von Vorsatz und grober Fahrlässigkeit
                        ausgeschlossen.</p><h4>4. Rechte an Unterlagen</h4><p>An Angebotsschriften, Abbildungen,
                        Zeichnungen, Kalkulationen und sonstigen Unterlagen – auch in elektronischer Form – hat
                        <i>scanmetrix.fm</i> die alleinigen Eigentums– und Urheberrechte.</p><p>Sie dürfen ohne ausdrückliche
                        Zustimmung von <i>scanmetrix.fm</i> Dritten nicht zugänglich gemacht, bekannt gegeben oder selbst oder
                        durch Dritte vervielfältigt werden.</p><p>Die <i>scanmetrix.fm</i> vom Auftraggeber zur Kenntnis
                        gebrachten Informationen und Unterlagen gelten als nicht vertraulich, sofern sie nicht
                        ausdrücklich als vertraulich gekennzeichnet sind.</p><h4>5. Konkretisierung des Leistungsumfangs
                        / Leistungsausschlüsse a) Stellung von Gerüsten, Energie u.a. Montagemitteln</h4>
                    <p><i>scanmetrix.fm</i> erbringt ausschließlich die im Vertrag ausdrücklich als Leistungspflicht von
                        <i>scanmetrix.fm</i> bezeichneten Leistungen.</p><p>Der Auftraggeber hat alle weiteren zur Ausführung
                        der Leistungen notwendigen Leistungen, Mitwirkungspflichten und Beistellungshandlungen auf
                        eigene Kosten und rechtzeitig auszuführen.</p><p>Dies betrifft insbesondere die zur Montage
                        erforderlichen Bedarfsgegenstände wie Gerüste, Hebezeuge und andere Vorrichtungen, Energie und
                        Wasser an der Verwendungsstelle einschließlich der Anschlüsse, Heizung und Beleuchtung,
                        Schutzkleidung und Schutzvorrichtungen, die infolge besonderer Umstände der Arbeitsstelle
                        erforderlich sind.</p><h5>b) Statische Angaben und Leitungsführung</h5><p>Notwendige Angaben
                        über die Lage verdeckt geführter Strom-, Gas-, Wasserleitungen oder ähnlicher Anlagen sowie die
                        ggf. erforderlichen statischen Angaben hat der Auftraggeber vor Beginn der Leistungserbringung
                        zur Verfügung zu stellen.</p><h5>c) Baustellenvorbereitung</h5><p>Vor Beginn der
                        Leistungserbringung muss die Arbeitsstelle und die Zuwegung freigeräumt und alle erforderlichen
                        Vorarbeiten soweit abgeschlossen sein, sodass die Leistung vereinbarungsgemäß begonnen und ohne
                        Unterbrechung durchgeführt werden kann.</p><h5>d) Zusatzkosten bei Verzögerungen</h5>
                    <p>Verzögert sich die Leistungserbringung durch nicht von <i>scanmetrix.fm</i> zu vertretende Umstände, so
                        hat der Auftraggeber in angemessenem Umfang die Kosten für Wartezeit und zusätzlich
                        erforderliche Reisen von Mitarbeitern der <i>scanmetrix.fm</i> oder des eingesetzten Montagepersonals
                        zu tragen.</p><h5>e) Arbeitsbescheinigungen</h5><p>Der Auftraggeber hat <i>scanmetrix.fm</i> die
                        Durchführung der Arbeiten unverzüglich nach Fertigstellung zu bescheinigen.</p><h5>f)
                        Feuergefährliche Arbeiten</h5><p>Bei der Durchführung der von <i>scanmetrix.fm</i> angekündigten
                        Schneid-, Schweiß-, Auftau-, Lötarbeiten und dergl. sind die für den Schutz des Eigentums und
                        der Gesundheit des Auftraggebers und Dritter erforderlichen Sicherheitsmaßnahmen vom
                        Auftraggeber selbst zu treffen.</p><h5>g) Frostschutzeinrichtungen</h5><p>Frostgefährdete
                        Bereiche sind vom Auftraggeber so zu schützen, dass von <i>scanmetrix.fm</i> zu erstellende bzw.
                        erstellte wasserführende Armaturen und Leitungen nicht gefährdet werden.</p><h5>h)
                        Außerbetriebnahme übergeordneter Brandmeldezentralen</h5><p>Die Leistungsgrenze der
                        <i>scanmetrix.fm</i> ist die anlagenspezifische Brandmeldezentrale (BMZ).</p><p>Die Überprüfung
                        übergeordneter BMZ sowie die Verarbeitung von ausgesendeten Signalen der BMZ an übergeordnete
                        BMZ gehört nicht zum Leistungsumfang der <i>scanmetrix.fm</i>.</p><p>Für die Außerbetriebnahme und
                        Überprüfung der Signalverarbeitung übergeordneter BMZ ist der Betreiber zuständig.</p><p>Er
                        koordiniert termingerecht die notwendigen Arbeiten der Beteiligten.</p><h5>i)
                        Unterbrechungsfreies Arbeiten</h5><p>Voraussetzung unserer Leistung ist ein unterbrechungsfreies
                        Arbeiten.</p><p>Erfordert die Leistung, Arbeiten, die am Folgetag fortgesetzt werden müssen,
                        wird die Löschanlage zwischenzeitlich außer Betrieb belassen.</p><p>Der Auftraggeber ist in den
                        Zeiten der Nichtfunktionstüchtigkeit alleinverantwortlich für die Sicherstellung des
                        Brandschutzes und organisiert eigenverantwortlich notwendige Kompensationsmaßnahmen.</p><h5>j)
                        Hinweis zum Einsatz wassergefährdender Stoffe</h5><p>Zum Betreiben von Löschanlagen kann ein
                        Einsatz von wassergefährdenden Stoffen wie z.B. Schaummittel, Korrosionsschutz, Algenschutz,
                        Frostschutz und/oder Kraftstoff, notwendig sein.</p><p>Systembedingt kann es zu einem Austritt
                        des Löschmediums aus dem Gebäude kommen.</p><p>Es ist Seitens des Betreibers sicherzustellen,
                        dass austretende Löschmittel und auch Kraftstoffe ordnungsgemäß aufgefangen und beseitigt
                        werden.</p><p><i>scanmetrix.fm</i> haftet im Auslösefall nicht für Umweltschäden und/oder
                        Umweltfolgeschäden und/oder sonstige Schäden jeglicher Art.</p><p>Wir empfehlen dringend eine
                        Abstimmung mit der zuständigen Behörde/dem Abwasserentsorger.</p><h5>k) Bereitstellung von
                        Lagerflächen / Zwischenlagerung von wassergefährdenden Stoffen:</h5><p>Der Betreiber stellt bei
                        Bedarf der <i>scanmetrix.fm</i> für die Zwischenlagerung von wassergefährdenden Stoffen einen Bereich
                        zu Verfügung:</p>
                    <ul>
                        <li>Das Lager ist so einzurichten, dass ein unmittelbarer Zugriff durch Betriebsfremde,
                            spielende Kinder, Diebstahl, Brandstiftung und Vandalismus nicht möglich ist.
                        </li>
                        <li>Einwirkungen durch Fahrzeuganprall oder sonstige gefährdende Einflüsse auf die Gebinde sind
                            zu verhindern.
                        </li>
                        <li>Die Lagerfläche darf sich in keinem Wasserschutzgebiet befinden.</li>
                        <li>Es ist seitens des Betreibers sicherzustellen, dass keine wassergefährdenden Stoffe in die
                            Kanalisation oder über unversiegelte Flächen auf dem Lagerplatz in das Erdreich gelangen
                            können.
                        </li>
                    </ul>
                    <h5>l) Löschwasserrückhaltung</h5><p>Es ist seitens des Auftraggebers sicherzustellen, dass die
                        Auslegung und Gestaltung der Löschwasserrückhaltung auf Basis der endgültigen Anlagenauslegung
                        der <i>scanmetrix.fm</i> überprüft wird, und falls erforderlich, die Löschwasserrückhaltung durch den
                        Auftraggeber an die Erfordernisse der Anlagenauslegung angepasst wird.</p><p>Die
                        Löschwasserrückhaltung sowie die Anpassungen sind nicht Bestandteil des Lieferumfanges der
                        <i>scanmetrix.fm</i>.</p><p><i>scanmetrix.fm</i> haftet im Auslösefall nicht für Umweltschäden und/oder
                        Umweltfolgeschäden und/oder sonstige Schäden jeglicher Art.</p><h5>m) Wasserqualität</h5><p>Es
                        ist seitens des Auftraggebers sicherzustellen, dass das zur Wasserversorgung der
                        Feuerlöschanlage zur Verfügung gestellte Wasser Trinkwasserqualität aufweist.</p><p>Die
                        Untersuchung der Wasserqualität sowie ggf. erforderliche Wasserreinigungs- bzw.
                        aufbereitungsmaßnahmen sind nicht Bestandteil des Lieferumfangs der <i>scanmetrix.fm</i>.</p><p>Die
                        <i>scanmetrix.fm</i> haftet im Falle der Versorgung der Feuerlöschanlage mit ungeeignetem Wasser nicht
                        für Korrosions-, Funktionsschäden und/oder sonstige Schäden jeglicher Art.</p><h4>6. VdS
                        Baustellenbesuche</h4><p>Vom VdS durchgeführte, notwendige Baustellenbesichtigungen (auch im
                        Rahmen der Errichteranerkennung der <i>scanmetrix.fm</i>) sind durch den Auftraggeber zu
                        ermöglichen.</p><h4>7. Abtretungsverbot</h4><p>Die Abtretung von Rechten aus dem
                        Vertragsverhältnis durch den Auftraggeber an Dritte bedarf der schriftlichen Zustimmung der
                        <i>scanmetrix.fm</i>.</p><h3>B. Preise und Zahlungsbedingungen</h3><h4>I. Allgemeine Bestimmungen zu
                        Preisen und Zahlungsbedingungen</h4><h5>1. Preisbindungsfrist</h5><p>Die Kalkulation der Preise
                        basiert auf einer Ausführung sämtlicher Arbeiten innerhalb der vereinbarten
                        Ausführungsfrist.</p><p>Bei wesentlicher Überschreitung der Ausführungsfrist kann <i>scanmetrix.fm</i>
                        wegen zwischenzeitlich eingetretenen Lohnsteigerungen einschließlich Lohnnebenkosten– und
                        Materialpreissteigerungen sowie erhöhter Frachtkosten und Kosten für Drittleistungen eine
                        angemessene Preisanpassung verlangen.</p><h5>2. Auswirkung der Änderung von Gesetzen /
                        Vorschriften</h5><p>Mehrkosten, die nach Vertragsschluss durch Änderung von Gesetzen,
                        Verordnungen, behördlichen und Verbands-Entscheidungen und Vorschriften entstehen, gehen voll zu
                        Lasten des Auftraggebers.</p><h5>3. Die angebotenen Preise von <i>scanmetrix.fm</i> enthalten:</h5>
                    <ul>
                        <li>Fracht und Anlieferung der aufgeführten Materialien und Werkzeuge frei Baustelle sowie
                            Rücktransport der Werkzeuge und des Restmaterials; befindet sich die Baustelle im Ausland,
                            so ist lediglich die Fracht, Anlieferung und der Rücktransport zu/von einem deutschen
                            See-/Flughafen bzw. zu/von der deutschen Grenze (bei Landtransport) im Preis enthalten;
                        </li>
                        <li>Ein Satz Dokumentationsunterlagen in digitaler Form, soweit es im Vertrag nicht anders
                            vereinbart und/oder gesetzlich anders vorgeschrieben ist.
                        </li>
                    </ul>
                    <h5>4. Nachfolgende Positionen sind in den angebotenen Preisen nicht enthalten:</h5>
                    <ul>
                        <li>Fundamentierungen und bauliche Nebenarbeiten, wie bspw. Erd-, Maurer-, Stemm-, Tischler-,
                            Klempner- und Malarbeiten;
                        </li>
                        <li>Herstellung von Verkleidungen und Isolationen;</li>
                        <li>Ggf. erforderliche Gestellung von Brandwachen;</li>
                        <li>Lieferung und Installation der Wasserzuleitungen bis an den Wasseranschluss bzw. die
                            Speisevorrichtung der Anlage;
                        </li>
                        <li>Kosten für die Gestellung von Baubuden, -container, -reinigung, -schilder, Bauwesen-und
                            Glasbruchversicherung;
                        </li>
                        <li>Kosten für Strom und Wasser - einschließlich Füllung von Behältern auf der Baustelle;</li>
                        <li>Lieferung und Installation von Kabelleitungen und elektrischen Anschlüssen;</li>
                        <li>Anschlüsse an Abflussleitungen und an elektrische Licht- und Kraftnetze;</li>
                        <li>Maßnahmen für den Potentialausgleich;</li>
                        <li>Evtl. erforderliche Bodenuntersuchungen oder Korrosionsschutzeinrichtungen für erdverlegte
                            Rohrleitungen;
                        </li>
                        <li>Urkunden, Steuern und Abnahmegebühren für die Anlage durch die technische Prüfstelle der VdS
                            Schadenverhütung GmbH &amp; Co. KG, den Technischen Überwachungsverein (TÜV) oder anderen
                            Institutionen;
                        </li>
                        <li>Kosten, die durch Überschreiten der regulären Arbeitszeiten entstehen (wie z.B. Überstunden-
                            und Nachtzuschläge, Fahrtkosten, usw.), sind in einem Nachtrag zu vereinbaren. Kommt ein
                            solcher Nachtrag nicht zustande, so gelten die tatsächlich anfallenden Kosten auf Seiten der
                            <i>scanmetrix.fm</i> für den zusätzlichen Aufwand an den Auftraggeber zzgl. eines Zuschlages von
                            500% als vereinbart.
                        </li>
                    </ul>
                    <h5>5. Stellung von Räumlichkeiten</h5><p>Für die Aufbewahrung von Materialien, Werkzeugen und den
                        Aufenthalt des Montagepersonals hat der Auftraggeber für die Dauer der vereinbarten
                        Ausführungsfrist einen verschließbaren Raum und für das Montagepersonal im Winter einen
                        beheizbaren und verschließbaren Aufenthaltsraum zur Verfügung zu stellen.</p><p>Ferner hat der
                        Auftraggeber dafür zu sorgen, dass dem Montagepersonal Toiletten und Waschgelegenheiten zur
                        Verfügung stehen.</p><p>Kommt der Auftraggeber diesen Verpflichtungen nicht nach, ist die
                        <i>scanmetrix.fm</i> nach erfolgter angemessener Nachfristsetzung berechtigt, diese Einrichtungen auf
                        Kosten des Auftraggebers herzurichten.</p><h5>6. Aufrechnungseinschränkung</h5><p>Der
                        Auftraggeber kann nur mit Forderungen aufrechnen, die unbestritten oder rechtskräftig
                        festgestellt sind oder aus demselben Rechtsverhältnis herrühren.</p><h5>7. Preisangabe /
                        Umsatzsteuer</h5><p>Sämtliche Preise sind Nettopreise und zuzüglich der jeweils geltenden
                        gesetzlichen Umsatzsteuer zu zahlen.</p><h5>8. Materialkosten / Entsorgung</h5><p>Sofern in den
                        Angebotspreisen von <i>scanmetrix.fm</i> kein Material enthalten ist, wird verbrauchtes Material und
                        verwendete Prüfmittel (Lecksuchspray und Prüfgase etc.) zu den jeweils geltenden Preisen
                        zusätzlich berechnet.</p><p>Ausgetauschte Teile bleiben im Eigentum des Auftraggebers und sind
                        von diesem zu entsorgen, sofern nicht <i>scanmetrix.fm</i> hierzu aufgrund zwingender gesetzlicher
                        Regelung verpflichtet ist.</p><p>Übernimmt <i>scanmetrix.fm</i> außerhalb einer gesetzlichen
                        Verpflichtung die Entsorgung der ausgetauschten Teile, so ist <i>scanmetrix.fm</i> berechtigt, sofern
                        die Entsorgung nicht gesetzlich zwingend kostenlos durchzuführen ist, neben den
                        Entsorgungskosten eine Entsorgungspauschale von € 10,00 pro Rechnung zu berechnen.</p>
                    <p><i>scanmetrix.fm</i> ist weiterhin berechtigt, für von <i>scanmetrix.fm</i> zu entsorgende Verpackungen und
                        deren Entsorgung eine Pauschale in Höhe von 10 % der Materialkosten, jedoch mindestens € 9,90
                        pro Rechnung zu erheben.</p><h5>9. Zahlungsbedingungen</h5><p>Sämtliche Forderungen sind sofort
                        fällig und spätestens 14 Tage nach Rechnungsstellung auszugleichen.</p><p>Sofern Aufträge einen
                        Rechnungswert von mehr als 10.000,- Euro haben, ist der Kaufpreis zahlbar zu 60% bei Eingang
                        unserer Auftragsbestätigung, mit weiteren 30% bei Lieferung, sowie 10% bei Abnahme der Anlage
                        mit dem Auftraggeber.</p><p>Spätestens jedoch 2 Wochen nach ordnungsmäßiger Mitteilung über die
                        Fertigstellung, sofern die Abnahme aus nicht von uns zu vertretenden Gründen nicht innerhalb von
                        2 Wochen nach Fertigstellungsmitteilung erfolgt.</p><p>Der Rechnungswert ist jeweils ohne Abzug
                        zahlbar, unabhängig von der Ausstellung einer Rechnung über angefallene Montagekosten.</p>
                    <h4>II. Besondere Zahlungsbedingungen für verschiedene Lieferungen und Leistungen</h4><h5>a)
                        Arbeiten auf Nachweis</h5><h6>1. Abrechnung nach Zeitaufwand / Arbeitsbescheinigungen</h6><p>Die
                        Leistungen für Lohnarbeiten werden nach Zeitaufwand abgerechnet.</p><p>Über den Zeitverbrauch
                        wird eine Arbeitsbescheinigung ausgestellt und dem vom Auftraggeber benannten Beauftragten zur
                        Bestätigung vorgelegt.</p><p>Wird vom Auftraggeber kein Bevollmächtigter benannt oder ist dieser
                        zur Prüfung und Gegenzeichnung der Bescheinigung nicht präsent, hat der Auftraggeber im
                        Zweifelsfall zu beweisen, dass die Aufschreibungen über den Zeitverbrauch unzutreffend sind.</p>
                    <h6>2. Bezugnahme auf aktuelle Preisliste</h6><p>Die Abrechnung erfolgt anhand der zum Zeitpunkt der
                        Beauftragung vereinbarten Preise mit der <i>scanmetrix.fm</i> GmbH.</p><h6>3. Reisezeiten</h6>
                    <p>Reisezeiten (An- und Abreise) und von <i>scanmetrix.fm</i> nicht zu vertretende Wartezeiten werden zu
                        den vorgenannten Lohnsätzen abgerechnet.</p><h6>4. Regelarbeitszeiten</h6><p>Die
                        Regelarbeitszeiten der <i>scanmetrix.fm</i> sind:</p><p>Montags bis Freitags 07:00 Uhr bis 17:00
                        Uhr.</p><h6>5. Zuschläge</h6><p>Für Überstunden, Nachtarbeit, Sonn - und Feiertagsarbeit sowie
                        für Erschwernisse werden prozentuale Zuschläge berechnet: Berechnungsgrundlage sind die
                        vereinbarten Stundensätze.</p>
                    <table>
                        <tbody>
                        <tr>
                            <td>Überstunden (ab der 9. Arbeitsstunde) und / oder Arbeiten außerhalb der <i>scanmetrix.fm</i>
                                Regelarbeitszeiten
                            </td>
                            <td>25 %</td>
                        </tr>
                        <tr>
                            <td>Nachtarbeit (zwischen 19:00 Uhr und 7:00 Uhr)</td>
                            <td>20 %</td>
                        </tr>
                        <tr>
                            <td>Arbeit an Sonntagen sowie an gesetzlichen Feiertagen, sofern diese auf einen Sonntag
                                fallen
                            </td>
                            <td>75 %</td>
                        </tr>
                        <tr>
                            <td>Arbeit an Oster- und Pfingstsonntag, ferner am 01. Mai und 1. Weihnachtsfeiertag, auch
                                wenn sie auf einen Sonntag fallen
                            </td>
                            <td>200 %</td>
                        </tr>
                        <tr>
                            <td>Arbeit an allen übrigen gesetzlichen Feiertagen, sofern sie nicht auf einen Sonntag
                                fallen
                            </td>
                            <td>200 %</td>
                        </tr>
                        <tr>
                            <td>Arbeiten auf Leitern und Gerüsten, deren Bodenbelag weniger als 90 cm breit ist, ab
                                einer Arbeitshöhe von 10 m
                            </td>
                            <td>20 %</td>
                        </tr>
                        <tr>
                            <td>Arbeiten in geschlossenen Behältern, in Kriechräumen bis zu einer Höhe von 1.20 m, in
                                Räumen mit Temperaturen ab 35°
                            </td>
                            <td>20 %</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>Fallen mehrere Zuschläge gleichzeitig an, sind alle Zuschläge nebeneinander zu zahlen.</p><h6>6.
                        Sonstige Lohnkosten</h6><p>Vereinbarte Ingenieurleistungen im Zusammenhang mit Montagearbeiten
                        wie Montageaufsicht, Abnahmen, Funktionsproben, Attest - und Zeichnungsänderungen usw. werden
                        gesondert abgerechnet.</p><h5>b) Wartungsarbeiten</h5><h6>1. Auswirkung von
                        Betriebsveränderungen des Objektes</h6><p>Die Vergütung für Inspektions- und Wartungsarbeiten
                        richtet sich nach dem vereinbarten Umfang und den Betriebsbedingungen der Anlage mit der
                        Maßgabe, dass sich bei Änderung des Umfanges der Anlage oder der Betriebsbedingungen
                        <i>scanmetrix.fm</i> berechtigt ist die Vergütung entsprechend vom Beginn des nächsten Kalendermonats
                        anzupassen.</p><p><i>scanmetrix.fm</i> informiert den Kunden vorab mittels eines Angebotes über die zu
                        erwartenden Mehrkosten.</p><h6>2. Zusatzvergütung für nicht vereinbarte Arbeiten</h6><p>Die
                        Vergütung für die im Wartungsvertrag beschriebene Vertragsleistung ist ein Pauschalpreis.</p>
                    <p>Instandsetzungs- und sonstige Arbeiten, Reparaturen und durch <i>scanmetrix.fm</i> nicht zu vertretende
                        Wartezeiten, die nicht in der Anlage zum Wartungsvertrag genannt sind, sind gesondert zu
                        beauftragen und werden nach den vorliegenden Bedingungen als Lohnarbeiten ausgeführt und
                        abgerechnet.</p><h6>3. Preisanpassungen</h6><p><i>scanmetrix.fm</i> ist berechtigt, die vereinbarten
                        Wartungspreise anzupassen, wenn sich die Höhe des Bundesecklohns gemäß § 5 des
                        Bundesrahmentarifvertrages für das Baugewerbe in Verbindung mit den jeweiligen
                        Lohntarifverträgen ("TV Lohn/West, TV Lohn/Ost TV Lohn/Berlin"). verändert.</p><p>Die Anpassung
                        erfolgt vorbehaltlich anderweitiger Abreden zum gleichen Zeitpunkt und im selben prozentualen
                        Verhältnis wie die Änderung des Bundesecklohnes im jeweiligen betrieblichen Geltungsbereich der
                        <i>scanmetrix.fm</i>.</p><h6>4. Automatische Beauftragung kleinerer Zusatzarbeiten</h6><p>Stellt sich
                        im Zuge der Wartung heraus, dass Instandsetzungsmaßnahmen zur Wiederherstellung des
                        Sollzustandes der Anlage unerlässlich sind und eine Unterlassung zur Sicherheits- oder
                        Betriebsgefährdung der Anlage führen würde, ist die <i>scanmetrix.fm</i> bereits mit Abschluss des
                        Wartungsvertrages beauftragt, diese Arbeiten bis zu einer Wertgrenze von € 500,00 (netto) auch
                        ohne gesonderten Auftrag der <i>scanmetrix.fm</i> GmbH durchzuführen.</p><h6>5.
                        Zurückbehaltungsrecht</h6><p>Bei Zahlungsverzug des Auftraggebers für zwei Monatsrechnungen
                        steht <i>scanmetrix.fm</i> ein Zurückbehaltungsrecht ihrer Leistungen zu. <i>scanmetrix.fm</i> wird demnach
                        ihre vereinbarte Leistung erst dann ausführen, wenn die fälligen Rückstände in voller Höhe
                        ausgeglichen wurden.</p><p>Die Zurückbehaltung begründet keinen Verzug der <i>scanmetrix.fm</i>.</p>
                    <h5>c) Lieferungen</h5><h6>1. Eigentumsvorbehalt / Sicherheitenfreigabe</h6><p>Die Gegenstände der
                        Lieferungen (Vorbehaltsware) bleiben Eigentum der <i>scanmetrix.fm</i> bis zur Erfüllung sämtlicher der
                        <i>scanmetrix.fm</i> zustehenden Ansprüche.</p><p>Soweit der Wert aller Sicherungsrechte, die der
                        <i>scanmetrix.fm</i> zustehen, die Höhe aller gesicherter Ansprüche um mehr als 20 % übersteigt, wird
                        <i>scanmetrix.fm</i> auf Verlangen des Auftraggebers einen entsprechenden Teil der Sicherungsrechte
                        freigeben.</p><h6>2. Verpfändungsverbot / Verbot der Sicherungsübereignung</h6><p>Während des
                        Bestehens des Eigentumsvorbehalts ist dem Auftraggeber eine Verpfändung oder
                        Sicherungsübereignung untersagt.</p><h6>3. Benachrichtigungspflicht bei Zugriff auf
                        Sicherungseigentum</h6><p>Bei Pfändungen, Beschlagnahmen oder sonstigen Verfügungen oder
                        Eingriffen Dritter hat der Auftraggeber <i>scanmetrix.fm</i> unverzüglich zu benachrichtigen.</p><h6>4.
                        Rücktritts- und Rücknahmevorbehalt</h6><p>Pflichtverletzungen des Auftraggebers, insbesondere
                        Zahlungsverzug, berechtigen <i>scanmetrix.fm</i> nach erfolglosem Ablauf einer dem Auftraggeber
                        gesetzten angemessenen Frist zur Leistung, zum Rücktritt und zur Rücknahme; die gesetzlichen
                        Bestimmungen über die Entbehrlichkeit einer Fristsetzung bleiben unberührt. Der Auftraggeber ist
                        zur Herausgabe verpflichtet.</p><h4>III. Sachmängel</h4><h5>1. Grundsatz</h5><p><i>scanmetrix.fm</i>
                        haftet für Sachmängel nur bei Lieferungen (einschließlich vereinbarter Montageleistungen) und
                        bei Instandsetzungsleistungen.</p><p>Für Inspektions- und Wartungsarbeiten wird keine
                        Gewährleistung für Sachmängel oder sonstige Haftung für den Zustand der inspizierten oder
                        gewarteten Gegenstände übernommen.</p><h5>2. Wahlrecht</h5><p>Sofern ein Sachmangel vorliegt,
                        kann der Auftraggeber die Beseitigung des Mangels (Nachbesserung) oder die Lieferung einer
                        mangelfreien Sache verlangen (Nachlieferung).</p><p>Ein Anspruch auf Nachlieferung besteht erst,
                        wenn <i>scanmetrix.fm</i> mindestens zweimal die Nachbesserung erfolglos versucht hat oder die
                        Nachbesserung unmöglich oder von <i>scanmetrix.fm</i> abgelehnt worden ist.</p><h5>3.
                        Gewährleistungsfristen</h5><p>Sachmängelansprüche verjähren in 12 Monaten, sofern nicht das
                        Gesetz zwingend eine längere Verjährungsfrist vorschreibt.</p><p>Die Verjährung beginnt bei
                        Lieferung ohne Montage mit Lieferung, bei Lieferung mit Montage mit Vollendung der Montage sowie
                        bei Instandsetzungsleistungen mit der Abnahme.</p><h5>4. Rügepflicht</h5><p>Der Auftraggeber hat
                        Sachmängel gegenüber der <i>scanmetrix.fm</i> unverzüglich schriftlich zu rügen.</p><h5>5.
                        Zurückbehaltungsrechte</h5><p>Bei berechtigten Mängelrügen dürfen Zahlungen des Auftraggebers
                        nur in dem Umfang zurückgehalten werden, die in einem angemessenen Verhältnis zu den
                        aufgetretenen Sachmängeln stehen.</p><h5>6. Unerhebliche Abweichungen</h5><p>Mängelansprüche
                        bestehen nicht bei nur unerheblicher Abweichung von der Soll-Beschaffenheit, sofern die
                        Abweichung die Brauchbarkeit der Sache für den vereinbarten oder vorausgesetzten Zweck nicht
                        beeinträchtigt wird.</p><h5>7. Nichteinhaltung von Wartungsterminen</h5><p>Die Nichteinhaltung
                        von Wartungsterminen berechtigt den Auftraggeber zum Rücktritt, sofern <i>scanmetrix.fm</i> die Wartung
                        nicht fristgerecht nachholt, nachdem der Auftraggeber hierfür eine angemessene Nachfrist, die
                        mindestens einem Monat betragen soll, gesetzt hat.</p><h4>IV. Haftung</h4><p><i>scanmetrix.fm</i>
                        haftet nach den gesetzlichen Bestimmungen, sofern der Auftraggeber Schadensersatzansprüche wegen
                        Vorsatz oder grober Fahrlässigkeit, einschließlich Vorsatz oder grober Fahrlässigkeit der
                        Vertreter oder Erfüllungsgehilfen von <i>scanmetrix.fm</i>, geltend macht.</p><p>Soweit keine
                        vorsätzliche Vertragsverletzung vorliegt, ist die Schadensersatzhaftung auf den vorhersehbaren,
                        typischerweise eintretenden Schaden begrenzt.</p><p><i>scanmetrix.fm</i> haftet nach den gesetzlichen
                        Bestimmungen, sofern <i>scanmetrix.fm</i> oder seine Vertreter oder Erfüllungsgehilfen schuldhaft eine
                        wesentliche Vertragspflicht verletzen.</p><p>Auch in diesem Fall ist die Schadensersatzhaftung
                        auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt.</p><p>Die Haftung für
                        einen von <i>scanmetrix.fm</i> verschuldeten Datenverlust beschränkt sich darüber hinaus auf die Kosten
                        für die Vervielfältigung der Daten von dem Auftraggeber zu erstellenden Sicherungskopien und für
                        die Wiederherstellung der Daten, die auch bei einer regelmäßigen, risikoadäquaten Sicherung der
                        Daten verloren gegangen wären.</p><p>Unterhält der Auftraggeber keine ordnungsgemäße und
                        risikoadäquate Datensicherung, haftet <i>scanmetrix.fm</i> für daraus entstehende Schäden nicht.</p>
                    <p>Soweit <i>scanmetrix.fm</i> technische Auskünfte gibt oder beratend tätig wird und diese Auskünfte oder
                        Beratung nicht zu dem von <i>scanmetrix.fm</i> geschuldeten, vertraglich vereinbarten Leistungsumfang
                        gehört, geschieht dies unentgeltlich und unter Ausschluss jeglicher Haftung.</p><p>Darüber
                        hinaus ist die Haftung für Fahrlässigkeit auf 5 Mio. € je Schadensfall beschränkt.</p><p>Die
                        Haftung wegen schuldhafter Verletzung des Lebens, des Körpers und der Gesundheit bleibt von den
                        vorstehenden Bestimmungen unberührt, dies gilt auch für die zwingende Haftung nach dem
                        Produkthaftungsgesetz.</p><p>Die Haftung von <i>scanmetrix.fm</i> aus einer von <i>scanmetrix.fm</i>
                        übernommenen Garantie bestimmt sich nicht nach den vorstehenden Regelungen, sondern nach den
                        Garantiebedingungen und den gesetzlichen Bestimmungen.</p><p>Die vorstehenden Regelungen gelten
                        unabhängig vom Rechtsgrund einer Haftung, insbesondere auch für außervertragliche und
                        deliktische Ansprüche.</p><p>Soweit nicht in dieser Ziffer IV. etwas anderes vereinbart ist, ist
                        die Haftung von <i>scanmetrix.fm</i> ausgeschlossen.</p><h4>V. Datenschutz</h4><p>Der Auftraggeber wird
                        darauf hingewiesen, dass von uns personenbezogene Daten (Name, Anschrift und Rechnungsdaten) des
                        Auftraggebers erhoben, gespeichert, verarbeitet und an Wirtschaftsauskunfteien übermittelt
                        werden können.</p><p>In diesem Zusammenhang werden wir den Wirtschaftsauskunfteien ggf. auch
                        Daten über eine vertragsgemäße oder nicht vertragsgemäße Abwicklung der mit den Auftraggebern
                        eingegangenen Vertragsbeziehung melden.</p><p>Diese Meldungen dürfen gemäß dem
                        Bundesdatenschutzgesetz nur erfolgen, soweit dies zur Wahrung unserer berechtigten Interessen
                        erforderlich ist und kein Grund zu der Annahme besteht, dass das schutzwürdige Interesse des
                        Auftraggebers an dem Ausschluss der Verarbeitung oder Nutzung überwiegt.</p><p>Die
                        Wirtschaftsauskunftei speichert die Daten, um den ihr angeschlossenen Unternehmen Informationen
                        zur Kreditwürdigkeit von Auftraggebern geben zu können. Die Wirtschaftsauskunftei stellt den ihr
                        angeschlossenen Unternehmen die Daten nur zur Verfügung, wenn diese ein berechtigtes Interesse
                        an der Datenübermittlung glaubhaft darlegen und kein Grund zu der Annahme besteht, dass der
                        Betroffene ein schutzwürdiges Interesse an dem Ausschluss der Übermittlung hat.</p><h4>VI.
                        Gestattung der Aufnahme in eine Referenzliste</h4><p>Der Auftraggeber gestattet <i>scanmetrix.fm</i>
                        die unentgeltliche Verwendung seines Firmennamens und seines Firmenlogo für Referenzlisten, auf
                        Werbemitteln wie Prospekte o.Ä., im Internet auf der <i>scanmetrix.fm</i>-Homepage oder in anderen
                        elektronischen Medien.</p><p>Diese Gestattung kann jederzeit ohne Angabe von Gründen schriftlich
                        gegenüber <i>scanmetrix.fm</i>, Unternehmenskommunikation widerrufen werden; in bereits gedruckten oder
                        veröffentlichten Medien darf der Firmenname bzw. das Firmenlogo weiterverwendet werden.</p>
                    <p>Diese Gestattung verpflichtet <i>scanmetrix.fm</i> nicht zur Aufnahme der gestattenden Firma in eine
                        bestehende oder neu zu erstellende Referenzliste.</p><p>Eine Nichtaufnahme führt in keinem Fall
                        zu einer Schadenersatzverpflichtung der <i>scanmetrix.fm</i> gegenüber der nichtaufgenommenen
                        Firma.</p><p>Die gestattende Firma erhält vor Veröffentlichung der Referenzliste einen Ausdruck
                        zur Kontrolle und zum Nachweis der beabsichtigten Verwendung.</p><p>Sollte einer Verwendung des
                        Namens und/oder Logos in Referenzlisten der <i>scanmetrix.fm</i> nicht zugestimmt werden, ist dieser
                        Absatz VI der AGB vollständig durch den Auftraggeber zu streichen und die Streichung zu
                        paraphieren.</p><h4>VII. Gewerbliche Schutzrechte und Urheberrechte</h4><p>Wenn Dritte aufgrund
                        der Benutzung der Lieferung / Leistung durch den Auftraggeber Ansprüche wegen Verletzung von
                        gewerblichen Schutzrechten oder Urheberrechten gegen diesen erheben, hat der Auftraggeber
                        <i>scanmetrix.fm</i> unverzüglich schriftlich zu unterrichten.</p><p>Für diese Fälle behalten sich
                        <i>scanmetrix.fm</i> alle Abwehr- und außergerichtlichen Maßnahmen zur Rechtsverteidigung vor.</p>
                    <p>Der Auftraggeber hat <i>scanmetrix.fm</i> hierbei zu unterstützen.</p><p>Für die Verletzung gewerblicher
                        Schutzrechte oder Urheberrechte Dritter haftet <i>scanmetrix.fm</i> nur, wenn diese Rechte dem
                        jeweiligen Dritten auch für das Territorium der Bundesrepublik Deutschland oder des Landes, in
                        das die Lieferung erfolgen soll, oder der Staaten, in denen der Kaufgegenstand nach dem
                        Vertragszweck verwendet werden soll, zustehen.</p><p>Letzteres gilt nur insoweit, als die vom
                        Vertragszweck erfassten Staaten in der Auftragsbestätigung ausdrücklich bezeichnet worden
                        sind.</p><h4>IX. Gerichtsstand und anwendbares Recht</h4><p>Alleiniger Gerichtsstand ist, wenn
                        der Auftraggeber Kaufmann ist, bei allen aus dem Vertragsverhältnis unmittelbar oder mittelbar
                        sich ergebenden Streitigkeiten der Sitz der <i>scanmetrix.fm</i> in Ratingen.</p>
                    <p><i>scanmetrix.fm</i> ist jedoch auch berechtigt, am allgemeinen Gerichtsstand des Auftraggebers zu
                        klagen.</p><p>Für alle Rechtsbeziehungen im Zusammenhang mit diesem Vertrag und seiner
                        Durchführung gilt deutsches materielles Recht unter Ausschluss des UN-Kaufrechts.</p></div>
            </Content>
            <Footer />
        </>
    }
}
