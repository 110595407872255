import React from 'react';
import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalWrapper = styled.div`
  width: 450px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const Header = styled.div`
  background: black; 
  padding: 20px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseIcon = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  &::before, &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 24px;
    background-color: white;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;

const Logo = styled.img`
  width: 150px;
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButton = styled.button`
  background: #3b97d3;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 5px;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const ModalComponent = ({ isOpen, onRequestClose }) => {
  if (!isOpen) return null;

  return (
    <ModalBackground onClick={onRequestClose}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Logo src="https://betreiber.scanmetrix.io/logo_text_white.svg" alt="Scanmetrix Logo" />
          <CloseIcon onClick={onRequestClose} />
        </Header>
        <Content>
          <form>
            <Input type="text" placeholder="Vorname*" required />
            <Input type="text" placeholder="Nachname*" required />
            <Input type="email" placeholder="E-Mail*" required />
            <Input type="text" placeholder="Telefonnummer" />
            <Input type="text" placeholder="Unternehmen" />
            <Input type="text" placeholder="Jobbezeichnung" />
            <div
              style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
            >
              <input type="checkbox" id="datenschutz" required />
              <label htmlFor="datenschutz" style={{ marginLeft: "10px" }}>
                Ich akzeptiere die Datenschutzbestimmung
              </label>
            </div>
            <CloseButton type="submit">Erfolgsgeschichte herunterladen</CloseButton>
          </form>
        </Content>
      </ModalWrapper>
    </ModalBackground>
  );
};

export default ModalComponent;

