import React, { useEffect } from "react"
import Start from "./pages/Start"
import Careers from "./pages/Careers"
import AGB from "./pages/AGB"
import About from "./pages/About"
import Privacy from "./pages/Privacy"
import Imprint from "./pages/Imprint"

import Maintenance from "./pages/Maintenance"
import Fullservice from "./pages/Fullservice"
import Digital from "./pages/Digital"
import Installation from "./pages/Installation"
import Construction from "./pages/Construction"
import Networking from "./pages/Networking"

import Media from "./pages/Media"
import AllBlogsPage from "./pages/AllBlogsPage"
import IndividuellBlog from "./pages/IndividuellBlog"
import SuccessStories from "./pages/SuccessStories"
import NotFound404 from "./pages/404"
import CookieConsent from "react-cookie-consent";

import {
    BrowserRouter,
    Route,
    useLocation,
    Routes
} from "react-router-dom"


function ScrollToTop({ children }) {
    const { pathname } = useLocation();

    useEffect(() => {
        const canControlScrollRestoration = 'scrollRestoration' in window.history
        if (canControlScrollRestoration) {
            window.history.scrollRestoration = 'manual';
        }

        window.scrollTo(0, 0);
    }, [pathname]);

    return children;
}

function App() {
    return <>
        <BrowserRouter>
            <ScrollToTop />
            <CookieConsent
                location="none"
                buttonText="Ich habe verstanden"
                cookieName="cookie-banner"
                overlayStyle={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                overlay={true}
                style={{ background: "white", color: "#20242b", fontWeight: 600, borderRadius: 5, boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)", fontSize: 20, width: "320px", padding: 16, left: "50%", top: "50%", transform: "translate(-50%, -50%)", alignItems: "center", justifyContent: "center", textAlign: "center" }}
                buttonStyle={{ color: "white", userSelect: "none", background: "#20242b", fontSize: "14px", padding: "16px 24px", textTransform: "uppercase", fontWeight: "bold", borderRadius: 4 }}
                expires={150}
            >
                <div style={{ width: "100%", fontSize: 24, marginBottom: 8, color: "#3b97d3", fontWeight: "bold" }}>Datenschutzhinweis</div>
                Diese Webseite verwendet Cookies, um ein optimales Nutzungserlebnis zu garantieren.
            </CookieConsent>
            <Routes>
                <Route path="*" element={<NotFound404 />} />
                <Route path="/" element={<Start />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/agb" element={<AGB />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/services/maintenance" element={<Maintenance />} />
                <Route path="/services/fullservice" element={<Fullservice />} />
                <Route path="/services/digital" element={<Digital />} />
                <Route path="/services/installation" element={<Installation />} />
                <Route path="/services/construction" element={<Construction />} />
                <Route path="/services/networking" element={<Networking />} />
                <Route path="/imprint" element={<Imprint />} />
                <Route path="/media" element={<Media />} />
                <Route path="/blog" element={<AllBlogsPage />} />
                <Route path="/blog/:id" element={<IndividuellBlog />} />
                <Route path="/successstories" element={<SuccessStories />} />
            </Routes>
        </BrowserRouter>
    </>
}

export default App

