import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"


const Text = styled.div`
    font-size: 22px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Fehler 404" subTitle="Diese Seite konnte nicht gefunden werden." image="/img/404.jpeg" />
            <Content>
                <div className="content">
                    <h3>Das tut uns Leid, ehrlich!</h3>
                    <h2>Hier gibt es nichts zu sehen...</h2>
                    <img src="/img/404.svg" style={{ height: 300, marginBottom: 48 }} />
                    <Text>
                        Wenn Sie diese Seite sehen, so handelt es sich vermutlich um einen Fehler - denn sie konnte nicht gefunden werden.
                    </Text>
                </div>
            </Content>
            <Footer />
        </>
    }
}
