import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { inject } from '@vercel/analytics'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

inject();

const client = new ApolloClient({
    uri: "https://api-eu-central-1.hygraph.com/v2/ckc0kun7m08ci01xl4h8500rq/master",
    cache: new InMemoryCache(),
  });
  

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>,
  );



