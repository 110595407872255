import styled from "styled-components"

export default styled.div`
    position: fixed;
    height: 100dvh;
    width: 100dvw;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(10px);
    z-index: 1000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  
    .menu {
        height: 100%;
        width: 75%;
        min-width: 400px;
        max-width: 768px;
        background: #20242b;
        color: white;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        box-shadow: -2px 0 6px 0 rgba(0, 0, 0, 0.25);
        overflow-y: scroll;
        transition: all 0.3s;
      
        > a {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 8px 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            width: 100%;
            font-size: 20px;
            min-height: 90px;
          
            &:last-child {
                margin-bottom: 48px;
                border-bottom: none;
            }
        }
      
        > .submenu {
            > .title {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 24px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.2);
              width: 100%;
              font-size: 20px;
              min-height: 90px;
            }
          
            > a {
                box-sizing: border-box;
                padding: 12px 32px;
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 18px;
                min-height: 90px;
              
                :nth-child(odd) {
                    background: rgba(255, 255, 255, 0.035);
                }
                
                .left {
                    width: 72px;
                  
                    i {
                        font-size: 40px;
                        color: #3b97d3;
                    }
                }
              
                .right {
                    flex: 1;
                    width: 100%;
                }
              
                .title {
                    font-weight: bold;
                    color: #3b97d3;
                    margin-bottom: 2px;
                }
              
                p {
                    width: 100%;
                }
            }
        }
    }
`
