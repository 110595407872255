import React, { Component } from "react"
import StyledCounters from "./styled/StyledCounters"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

class Counter extends Component {
    state = { animated: false }

    render() {
        return <div className="item">
            <div className="icon"><i className={`fa-duotone fa-${this.props.icon}`} /></div>
            <div className="number">
                <CountUp
                    start={0}
                    end={this.props.value}
                    duration={3}
                    decimals={0}
                    separator="."
                    suffix={this.props.suffix || "+"}
                >
                    {({ countUpRef, start }) => (
                        <VisibilitySensor partialVisibility onChange={isVisible => {
                            if(isVisible && !this.state.animated) {
                                this.setState({ animated: true })
                                start()
                            }
                        }} delayedCall>
                            <span ref={countUpRef} />
                        </VisibilitySensor>
                    )}
                </CountUp>
            </div>
            <div className="description">{this.props.description}</div>
        </div>
    }
}

export default class extends Component {
    render() {
        return <div style={{ width: "100%", background: "#eff2f7" }}>
            <StyledCounters>
                <div className="container">
                    <div className="title">
                        <h3>Unsere Fakten in Zahlen.</h3>
                        <h2>Statistik seit Januar 2021</h2>
                    </div>
                    <div className="counters">
                        <Counter description="zufriedene Kunden" value={130} icon="users" />
                        <Counter description="ausgeführte Aufträge" value={8500} icon="wrench" />
                        <Counter description="betreute Liegenschaften" value={9500} icon="store" />
                        <Counter description="gewartete Anlagen" value={75000} icon="hard-hat" />
                        <Counter description="gefahrene Kilometer" value={1250000} icon="road" />
                    </div>
                </div>
            </StyledCounters>
        </div>
    }
}
