import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;
  position: relative;
  background: #20242b;
  user-select: none;
  overflow: hidden;

  img {
      position: absolute;
      z-index: 1;
      opacity: 0.075;
      -webkit-user-drag: none;
      height: 200%;
  }
  
  .container {
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    text-align: center;
    color: white;
    font-size: 32px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
    .phone {
        color: #3b97d3;
        font-weight: 900;
    }
    
    .title {
        font-size: 48px;
        font-weight: 600;
    }
    
    .button {
        background: white;
        color: #20242b;
        font-size: 20px;
        height: 64px;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        margin-top: 32px;
        display: inline-flex;
        font-weight: 900;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
            color: white;
            background: #3b97d3;
        }
    }
  }
`
