import styled from "styled-components"

export default styled.div`
    width: 100%;
    background: #20242b;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 160px 0;
    padding-bottom: 128px;
    clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0% 100%);
  
    @media screen and (max-width: 1024px) {
      clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
      
    }
    
    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 16px;
        max-width: 1500px;


      .title {
        text-align: center;
        user-select: none;
        margin-bottom: 64px;

        h2 {
          font-size: 48px;
          width: 100%;
          box-sizing: border-box;
          padding: 0 32px;
        }

        h3 {
          font-weight: 900;
          font-size: 24px;
          color: #3b97d3;
        }

        h4 {
          max-width: 700px;
          text-align: center;
          margin: 0 auto;
          font-size: 22px;
          font-weight: 500;
          line-height: 32px;
        }
      }
      
        .counters {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;

          .item {
            text-align: center;
            user-select: none;
            margin: 40px auto;
            padding: 0 24px;

            .number {
              font-weight: 800;
              font-size: 40px;
            }

            .icon {
              color: #3b97d3;
              margin-bottom: 12px;
              font-size: 48px;
            }

            .description {
              font-size: 24px;
            }
          }
        }
    }
`
