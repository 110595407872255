import React, { Component } from "react"
import Navigation from "../Start/modules/Navigation"
import Header from "../Start/modules/SmallHeader"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"

const software = [
    {
        title: "Flyer zum Dienstleister ERP",
        description: "Alle Infos zu unserem ERP auf einen Blick.",
        size: "1.27 MB",
        type: "PDF",
        link: "/downloads/Flyer_ERP.pdf"
    },
    {
        title: "ERP Use Case Brandschutz",
        description: "Wie unser ERP den Brandschutz digitalisieren kann.",
        size: "1.86 MB",
        type: "PDF",
        link: "/downloads/Use_Case_Brandschutz.pdf"
    },
    {
        title: "Flyer zum Betreiber CAFM",
        description: "Alle Infos zu unserem CAFM auf einen Blick.",
        size: "1.25 MB",
        type: "PDF",
        link: "/downloads/Flyer_CAFM.pdf"
    },
    {
        title: "CAFM-Implementierungsleitfaden",
        description: "Leitfaden für Betreiber, die planen, unser CAFM zu implementieren.",
        size: "1.49 MB",
        type: "PDF",
        link: "/downloads/Implementierungsleitfaden.pdf"
    },
    {
        title: "Präsentation für Betreiber",
        description: "Plattform und Zusammenspiel von Betreiber & Dienstleister.",
        size: "6.84 MB",
        type: "PDF",
        link: "/downloads/Praesentation_Betreiber_Plattformkonzept.pdf"
    },
    {
        title: "Präsentation für Dienstleister",
        description: "ERP-Präsentation für Dienstleistungsbetriebe.",
        size: "1.01 MB",
        type: "PDF",
        link: "/downloads/Praesentation_Dienstleister.pdf"
    },
    {
        title: "Das Ökosystem (Plattformkonzept)",
        description: "Alle Module und Verbindungen auf einen Blick erklärt.",
        size: "296 KB",
        type: "PDF",
        link: "/downloads/Oekosystem.pdf"
    },
    {
        title: "Technische Datenblätter und Anforderungen",
        description: "Technische Mindestvoraussetzungen und Infos zur Hardware.",
        size: "313 KB",
        type: "PDF",
        link: "/downloads/Technische_Datenblaetter.pdf"
    },
    {
        title: "TGA-Struktur",
        description: "Unsere TGA-Struktur in einer Folie erklärt.",
        size: "23 KB",
        type: "PDF",
        link: "/downloads/TGA_Struktur.pdf"
    },
    {
        title: "Field Service Betriebshandbuch",
        description: "Das Betriebshandbuch für Field Service Monteure.",
        size: "3.59 MB",
        type: "PDF",
        link: "/downloads/Betriebshandbuch.pdf"
    }
]

const ads = [
    {
        title: "Unternehmensvorstellung und Facility Management",
        description: "Leitbild und Übersicht der angebotenen FM-Dienstleistungen.",
        size: "1.18 MB",
        type: "PDF",
        link: "/downloads/Unternehmensvorstellung.pdf"
    },
    {
        title: "Übersicht Baudienstleistungen",
        description: "Dienstleistungsportfolio im Bereich Bauwesen.",
        size: "909 KB",
        type: "PDF",
        link: "/downloads/Uebersicht_Baudienstleistungen.pdf"
    },
    {
        title: "Video zur EXPO REAL 2023",
        description: "Einminütiges Video mit Leistungsübersicht von scanmetrix.fm",
        size: "6.57 MB",
        type: "MP4",
        link: "/downloads/Unternehmensvorstellung.mp4"
    }
]

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Medien & Downloads" subTitle="Alles über unsere Dienstleistungen und Produkte." image="/img/media.jpg" />
            <Content>
                <h3>Alle Downloads im Blick.</h3>
                <h2>Software Informationen</h2>
                <div className="grid">
                    {software.map((item, index) => <div className="item" key={index}>
                        <div className="size"><div className="type">{item.type}</div>{item.size}</div>
                        <div className="title">{item.title}</div>
                        <div className="description">{item.description}</div>
                        <div className="download" onClick={() => window.open(item.link, "_blank")}>
                            <i className="fas fa-arrow-down"></i>
                            <p>Herunterladen</p>
                        </div>
                    </div>)}
                </div>
            </Content>
            <div style={{ backgroundColor: "#eff2f7" }}>
                <Content>
                    <h3>Alle Downloads im Blick.</h3>
                    <h2>Dienstleistungen & Unternehmen</h2>
                    <div className="grid">
                        {ads.map((item, index) => <div className="item" key={index}>
                            <div className="size"><div className="type">{item.type}</div>{item.size}</div>
                            <div className="title">{item.title}</div>
                            <div className="description">{item.description}</div>
                            <div className="download" onClick={() => window.open(item.link, "_blank")}>
                                <i className="fas fa-arrow-down"></i>
                                <p>Herunterladen</p>
                            </div>
                        </div>)}
                    </div>
                </Content>
            </div>
            <Footer />
        </>
    }
}
