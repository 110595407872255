import React, { Component } from "react"
import StyledInput from "./styled/StyledInput"
import StyledContact from "./styled/StyledContact"
import { InlineWidget } from "react-calendly"
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
import styled from "styled-components";

const StyledCheckbox = styled.div`
    display: flex;

    .checkbox {
      width: 24px;
      height: 24px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      flex-shrink: 0;
      margin-right: 16px;
      display: flex;
        align-items: center;
      justify-content: center;
      color: #3b97d3;
      cursor: pointer;
    }

    label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.75);
      user-select: none;
      font-size: 20px;
      

      a {
          color: #3b97d3;
          text-decoration: none;
      }
    }
`

class Checkbox extends Component {
    render() {
        return <StyledCheckbox onClick={this.props.toggle}>
            <div className="checkbox">
                {this.props.checked && <i className="fas fa-check" />}
            </div>
            <label>{this.props.label}</label>
        </StyledCheckbox>
    }
}

class Input extends Component {
    render() {
        return <StyledInput>
            <label>{this.props.label}{this.props.required && <i className="fas fa-asterisk" />}</label>
            {!this.props.textArea && <input spellCheck="false" value={this.props.value || ""} onChange={e => this.props.onChange(e.target.value)} />}
            {this.props.textArea && <textarea spellCheck="false" value={this.props.value || ""} onChange={e => this.props.onChange(e.target.value)} />}
        </StyledInput>
    }
}

export default class extends Component {
    state = { privacyChecked: false, name: null, phone: null, email: null, company: null, message: null }

    render() {
        return <StyledContact id="contact">
            <div className="arch" />
            <div className="container">
                <div className="titles">
                    <h3>Persönlich erreichbar - immer.</h3>
                    <h2>Kontaktieren Sie uns</h2>
                    <h4>Vereinbaren Sie ein Kennenlerngespräch oder senden Sie uns eine Nachricht über das Kontaktformular.</h4>
                </div>
            </div>
            <div className="container">
                <div className="contacts">
                    <div>
                        <i className="fa-duotone fa-envelope" />
                        info@scanmetrix.fm
                    </div>
                    <div>
                        <i className="fa-duotone fa-phone" />
                        02102 56 56 930
                    </div>
                    <div>
                        <i className="fa-duotone fa-map-marker" />
                        Ratingen
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="left">
                    <div className="form">
                        <h4>Kontaktformular</h4>
                        <Input label="Ihr vollständiger Name" value={this.state.name} onChange={name => this.setState({ name })} required />
                        <Input label="Ihre E-Mail-Adresse" value={this.state.email} onChange={email => this.setState({ email })} required />
                        <Input label="Ihre Telefonnummer" value={this.state.phone} onChange={phone => this.setState({ phone })} required />
                        <Input label="Ihr Firmenname" value={this.state.company} onChange={company => this.setState({ company })} required />
                        <Input label="Ihre Nachricht an uns" value={this.state.message} onChange={message => this.setState({ message })} textArea required />
                        <div className="privacy">
                            <Checkbox checked={this.state.privacyChecked} toggle={() => this.setState({ privacyChecked: !this.state.privacyChecked })} label={<>Ich habe den <a href="https://scanmetrix.fm/privacy-policy" target="_blank">Datenschutzhinweis</a> der scanmetrix.fm GmbH gelesen und stimme der Weiterverarbeitung meiner Daten für diese Kontaktaufnahme zu.</>} />
                        </div>
                        <div className="button" onClick={() => {
                            if(this.state.privacyChecked) {
                                let errors = []

                                if(!this.state.name || (this.state.name && this.state.name.trim().length === 0)) errors.push("name")
                                if(!this.state.email || (this.state.email && this.state.email.trim().length === 0)) errors.push("email")
                                if(!this.state.message || (this.state.message && this.state.message.trim().length === 0)) errors.push("message")

                                if(errors.length === 0) {
                                    if(this.state.email.toLowerCase()
                                        .match(
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        )) {

                                        Swal.fire({
                                            title: "Kontaktanfrage erfolgreich!",
                                            html: "Ihre Kontaktanfrage wurde erfolgreich an uns übermittelt.<br /><br />Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.",
                                            icon: "success",
                                            didOpen: () => {
                                                Swal.showLoading()
                                            },
                                            confirmButtonText: "Verstanden"
                                        })

                                        emailjs.send("service_0kxwk21", "template_vrsw9k1", {
                                            name: this.state.name,
                                            phone: this.state.phone,
                                            company: this.state.company,
                                            email: this.state.email,
                                            message: this.state.message
                                        }, "user_x8940LKO2DVMwnbhkeQXQ").then(data => {
                                            console.log(data)
                                            Swal.hideLoading()

                                            if(data.status === 200) {
                                                this.setState({ name: null, phone: null, email: null, company: null, message: null, privacyChecked: false })
                                            } else {
                                                Swal.fire({
                                                    title: "Fehlgeschlagen.",
                                                    text: "Beim Versenden der Nachricht ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut oder nutzen Sie eine unserer anderen Kontaktmöglichkeiten.",
                                                    icon: "warning",
                                                    confirmButtonText: "Verstanden"
                                                })
                                            }
                                        })
                                    } else {
                                        Swal.fire({
                                            title: "Fehlgeschlagen.",
                                            text: "Bitte überprüfen Sie Ihre E-Mail-Adresse auf Richtigkeit.",
                                            icon: "warning",
                                            confirmButtonText: "Verstanden"
                                        })
                                    }
                                } else {
                                    Swal.fire({
                                        title: "Fehlgeschlagen.",
                                        html: "Bitte überprüfen Sie Ihre Eingaben und stellen Sie sicher, dass " + (errors.length > 1 ? "folgende Felder ausgefüllt sind" : "folgendes Feld ausgefüllt ist") + ":<br/><br/><ul>" + errors.map(error => ("<li>" + ({
                                            name: "Ihr Name",
                                            email: "E-Mail-Adresse",
                                            message: "Ihre Nachricht an uns"
                                        })[error] + "</li>")).join("") + "</ul>",
                                        icon: "warning",
                                        confirmButtonText: "Verstanden"
                                    })
                                }
                            } else {
                                Swal.fire({
                                    title: "Fehlgeschlagen.",
                                    text: "Bitte akzeptieren Sie den Datenschutzhinweis, bevor Sie fortfahren.",
                                    icon: "warning",
                                    confirmButtonText: "Verstanden"
                                })
                            }
                        }}><i className="fa-duotone fa-envelope" /> Nachricht absenden</div>
                    </div>
                </div>
                <div className="right">
                    <InlineWidget url="https://calendly.com/scanmetrixfm/30min?hide_landing_page_details=1&hide_gdpr_banner=1" styles={{ width: "100%", height: "1096px", background: "transparent", marginTop: -50, userSelect: "none" }} />
                </div>
            </div>
        </StyledContact>
    }
}
