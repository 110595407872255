import React, { Component } from "react"
import StyledServices from "./styled/StyledServices"
import { Link } from "react-router-dom"

export default class extends Component {
    render() {
        return <StyledServices>
            <div className="container">
                <div className="title">
                    <h3>Rund um's Facility Management.</h3>
                    <h2>Unsere Dienstleistungen</h2>
                    <h4>
                        Vollumfänglicher Service in jeder Hinsicht. Kein Projekt zu klein, kein Projekt zu groß. Bundesweit, preisstabil und stets digital.
                    </h4>
                </div>
                <div className="services">
                    <Link to="/services/maintenance" className="service">
                        <i className="fa-duotone fa-cog" />
                        <p className="title">Technische Anlagenwartung</p>
                        <p className="description">Von Wartungen/Instandhaltungen Ihrer technischen Brandschutz- und Raumlufttechnik-Anlagen bis hinzu Hygieneinspektionen bietet Ihnen scanmetrix ausgezeichnete und umfangreiche Dienstleistungen für Ihre TGA an.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                    <Link to="/services/construction" className="service">
                        <i className="fa-duotone fa-person-digging" />
                        <p className="title">Umbauten und Sanierungen</p>
                        <p className="description">Sie suchen nach einem Dienstleister, der für sie technische Anlagen einrichtet oder Planungen und Projektierungen für Sie übernimmt? Wir bieten Ihnen umfangreiche Leistungen im Bereich Umbauten und Sanierung an.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                    <Link to="/services/installation" className="service">
                        <i className="fa-duotone fa-wrench" />
                        <p className="title">Anlageninstallation</p>
                        <p className="description">Von Löschanlagen bis hin zu Tageslichtsystemen, scanmetrix bietet Ihnen eine normgerechte und fachmännische Installation Ihrer technischen Anlagen in Ihren Liegenschaften an.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                    <Link to="/services/digital" className="service">
                        <i className="fa-duotone fa-qrcode" />
                        <p className="title">Digitale Gebäudeaufnahme</p>
                        <p className="description">Wir nehmen Ihr Gebäude digital und weitervertbar auf, um für eine optimale Datengrundlage für den weiteren Betrieb Ihres Gebäudes in einem CAFM-System zu sorgen.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                    <Link to="/services/networking" className="service">
                        <i className="fa-duotone fa-chart-network" />
                        <p className="title">Dienstleister Networking</p>
                        <p className="description">Sie benötigen Dienstleister für Ihre Gewerke, in Ihrer Region oder bundesweit? Wir helfen Ihnen, aus unserem Dienstleisternetzwerk den richtigen Partner für Sie zu finden. Hierbei greifen wir auf Unternehmen zurück, mit denen wir selbst langjährige Erfahrungen sammeln konnten.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                    <Link to="/services/fullservice" className="service">
                        <i className="fa-duotone fa-hand-holding-heart" />
                        <p className="title">Full-Service-Paket</p>
                        <p className="description">Sie benötigen einen vollumfänglichen Dienstleister? Einen Dienstleister, der alle Ihre Gewerke regional oder bundesweit „digital“ abdeckt? Wir gewährleisten einen ganzheitlichen, volldigitalisierten Wartungs-, Betreuungs- und Serviceumfang.</p>
                        <div className="more">Mehr erfahren <i className="fas fa-arrow-right" /></div>
                    </Link>
                </div>
            </div>
        </StyledServices>
    }
}
