import React, { Component } from "react"
import StyledYouTube from "./styled/StyledYouTube"

export default class extends Component {
    render() {
        return <StyledYouTube>
            <div className="background" />
            <div className="container">
                <div className="left">
                    <h2>Unser <i className="fa-brands fa-youtube" /> YouTube Kanal</h2>
                    <h3>Denn Videos sagen mehr als tausend Bilder.</h3>
                </div>
                <div className="right">
                    <a className="video" target="_blank" href="https://youtube.com/@scanmetrix">
                        <img src="/img/arrow.svg" />
                        <i className="fas fa-play" />
                    </a>
                </div>
            </div>
        </StyledYouTube>
    }
}