import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 128px 0;
  
  .container {
    width: 100%;
    box-sizing: border-box;
    max-width: 1500px;
    position: relative;
    padding: 0 64px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }

    h2 {
      font-size: 48px;
      color: #20242b;
      user-select: none;
      margin-bottom: 64px;
    }

    h3 {
      font-weight: 900;
      font-size: 24px;
      color: #3b97d3;
      user-select: none;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      user-select: none;
    }
    
    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 32px;
      
        @media screen and (max-width: 1500px) {
            grid-template-columns: repeat(3, 1fr);
        }
      
      @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }
      
        .item {
            border: 1px solid rgba(0, 0, 0, 0.2);
            background: white;
            padding: 32px;
            border-radius: 4px;
          
            .size {
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-bottom: 12px;
              
                .type {
                    background: #20242b;
                    color: white;
                    padding: 2px 12px;
                    margin-right: 8px;
                    border-radius: 4px;
                }
            }
          
            .title {
                font-weight: 600;
                font-size: 26px;
            }
          
            .description {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                margin-top: 16px;
            }
          
            .download {
                background: #3b97d3;
                color: white;
                cursor: pointer;
                display: flex;
                box-sizing: border-box;
                width: 100%;
                padding: 14px;
                align-items: center;
                border-radius: 4px;
                user-select: none;
                justify-content: center;
                margin-top: 24px;
                font-size: 20px;
                transition: all 0.3s;
              
                &:hover {
                    background: #20242b;
                }
              
                i {
                    margin-right: 10px;
                }
            }
        }
    }
  }
`
