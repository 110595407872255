import React from 'react'
import Footer from '../../../Start/modules/Footer';
import { useQuery, gql } from "@apollo/client";
import { Link,  useParams } from 'react-router-dom'; // Import useParams
import "./styled/StyledIndividuellBlogPost.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faPeopleGroup, faBirthdayCake, faUserGraduate, faVideo, faBuildingFlag, faIdBadge, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown'; // Import the react-markdown library

const ASSETS_QUERY = gql`
  query Assets($id: ID!) {
    blogpost(where: {id: $id}) {
      id
      color{hex}
      description
      date
      icon
      locale
      publishedAt
      shortDescription
      stage
      title
      updatedAt
      createdAt
    }
  }
`;

const IndividuellBlogPost = () => {

  // to change the formats of date
  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(originalDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Get the "id" parameter from the URL
  const { id } = useParams();

  const { data, loading, error } = useQuery(ASSETS_QUERY, {
    variables: { id },
  });

  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;
 
  const blogpostNew = data.blogpost; 

  return (
    <>

    <div className='general-blog'>
    <section className='container1' >

    <div className='top1' >
      <h1 className='blog-title'>{blogpostNew.title}</h1>
      
      <div className="anim-border">
      {blogpostNew.icon === 'hands-helping' && (
        <p className='fa-icon' >
          <FontAwesomeIcon icon={faHandsHelping} />
        </p>
      )}
      {blogpostNew.icon === 'birthday-cake' && (
        <p className='fa-icon1'>
          <FontAwesomeIcon icon={faBirthdayCake} />
        </p>
      )}
      {blogpostNew.icon === 'user-graduate' && (
        <p className='fa-icon1'>
          <FontAwesomeIcon icon={faUserGraduate} />
        </p>
      )}
      {blogpostNew.icon === 'badge-percent' && (
        <p className='fa-icon2'>
          <FontAwesomeIcon icon={faIdBadge} />
        </p>
      )}
      {blogpostNew.icon === 'people-group' && (
        <p className='fa-icon'>
          <FontAwesomeIcon icon={faPeopleGroup} />
        </p>
      )}
      {blogpostNew.icon === 'video' && (
        <p className='fa-icon'>
          <FontAwesomeIcon icon={faVideo} />
        </p>
      )}
      {blogpostNew.icon === 'building-flag' && (
        <p className='fa-icon'>
          <FontAwesomeIcon icon={faBuildingFlag} />
        </p>
      )}
    </div>
  </div>

      {/* <p>Locale: {blogpostNew.locale}</p> */}
      <p className='Short-Description' >{blogpostNew.shortDescription}</p>
      

      {/* flex section */}
      <section className='credits' >

      <div>
      <img className='blog-img' src="https://handwerk.scanmetrix.io/devices.png" alt='blogImg' />
      </div>

      <div className='right-side' >
      <img className='writer-img' src="https://scanmetrix.fm/img/team/aysu.png" alt='writerImg' />
      <div className='blog-date'>
      <p className='blog-dates'> <FontAwesomeIcon icon={faCircleCheck} className='fa-check' /> BLOG WRITTEN BY: AYSU ÖZTÜRK</p>
      <p className='blog-dates'> <FontAwesomeIcon icon={faCircleCheck} className='fa-check' /> BLOG CREATED: {formatDate(blogpostNew.createdAt)}</p>
      <p className='blog-dates' > <FontAwesomeIcon icon={faCircleCheck} className='fa-check' /> BLOG {blogpostNew.stage}: {formatDate(blogpostNew.date)}</p>
      <p className='blog-dates'> <FontAwesomeIcon icon={faCircleCheck} className='fa-check' /> BLOG UPDATED: {formatDate(blogpostNew.updatedAt)}</p>
      </div>
      <div className="anim-border1">
        <h1>BLOG TEILEN!</h1>
      </div>
     <div className='bottom-buttons' >
      <a className='link-button' href='https://www.linkedin.com/company/scanmetrix-fm-gmbh' ><div className="more-new">auf linkedin </div> </a>
     <a className='link-button' href='https://www.instagram.com/scanmetrixfm/' ><div className="more-new"> auf instagram </div> </a>
     <a className='link-button' href='https://www.facebook.com/scanmetrix.fm/' ><div className="more-new">auf facebook </div> </a>
     <Link className='link-button' to='/blog' ><div className="more-new">aktuelles Blog </div> </Link>
     </div>
      </div>

      </section>

      <div className='Long-Description'>
      {/* Render the Markdown content as HTML */}
      <ReactMarkdown>{blogpostNew.description}</ReactMarkdown>
      </div>
    
      
    </section>
    </div>
    
    <Footer />
    </>
  )
}

export default IndividuellBlogPost





    