import styled from "styled-components"

export default styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 128px 0;
  margin-top: 150px;
  
  > .background {
      position: absolute;
      width: 50%;
      left: 0;
      height: 100%;
    background-attachment: fixed;
      top: 0;
      border-radius: 0 15px 15px 0;
      background-size: cover;
      background-position: center;
      background-image: linear-gradient(30deg,rgb(59 64 211),rgba(59,151,211,0.9)),url(/img/youtube.jpg);
    
      @media screen and (max-width: 1500px) {
          width: 100%;
          border-radius: 0;
      }
  }
  
  .container {
    position: relative;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
      
    .left, .right {
        width: 50%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        height: 270px;
      
        @media screen and (max-width: 900px) {
          width: 100%;
          text-align: center;
        }
    }
    
    .right {
        align-items: center;
        perspective: 750px;
        
        .video {
            display: flex;
            text-decoration: none;
            position: relative;
            align-items: center;
            justify-content: center;
            width: 480px;
            height: 270px;
            border-radius: 5px;
            background-image: linear-gradient(0deg,#20242b,rgb(32 36 43 / 50%)),url(/img/thumbnail.jpg);
            background-position: center;
            background-size: cover;
            transform: rotate3d(0, -1, 0, 15deg);
            box-shadow: 3px 0 16px 0 rgba(0, 0, 0, 0.25);
            cursor: pointer;
            transition: all 0.3s;

            @media screen and (max-width: 1500px) {
                border: 5px solid white;
            }
          
            @media screen and (max-width: 1080px) and (min-width: 901px) {
                width: 360px;
                height: 202.5px;
                background-image: linear-gradient(0deg,#20242b,rgb(32 36 43 / 30%)),url(/img/thumbnail.jpg);
            }

            @media screen and (max-width: 900px) {
                transform: none;
            }
          
            @media screen and (max-width: 650px) {
                width: 360px;
                height: 202.5px;
                background-image: linear-gradient(0deg,#20242b,rgb(32 36 43 / 30%)),url(/img/thumbnail.jpg);
            }
          
            &:hover {
                transform: rotate3d(0, 0, 0, 0deg);
              
                i {
                    background: rgba(255, 255, 255, 0.65);
                }
            }
          
            img {
                z-index: 3;
                position: absolute;
                width: 200px;
                right: -100px;
                -webkit-user-drag: none;
                user-select: none;
                top: -100px;

                @media screen and (max-width: 1500px) {
                    display: none;
                }
            }
          
            i {
                font-size: 24px;
                height: 64px;
                width: 64px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                background: rgba(255, 255, 255, 0.35);
                transition: background 0.3s;
            }
        }
    }

    h2 {
      font-size: 48px;
      color: white;
      user-select: none;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      color: white;
      user-select: none;
    }
  }
`
