import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;
  background: #eff2f7;
  
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    
    @media screen and (max-width: 1024px) {
        padding: 0 48px;
    }
    
    .services {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 32px;
      
        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 910px) {
            grid-template-columns: 1fr;
        }
      
        .service {
            text-decoration: none;
            color: inherit;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            padding: 32px;
            user-select: none;
            cursor: pointer;
            // transition: all 0.3s;
            transition: all 0.3s ease;
            background: white;
          
            &:hover {
                border-color: #3b97d3;
                background: #3b97d3;
                color: white;
                transform: scale(1.05);
              
                i.fa-duotone {
                    color: white;
                }
              
                .more {
                    color: white;
                }
            }
          
            i.fa-duotone {
                color: #3b97d3;
                font-size: 40px;
                transition: all 0.3s;
            }
          
            .title {
                font-weight: 900;
                font-size: 26px;
                margin: 16px 0;
            }
          
            .description {
                margin-bottom: 16px;
                line-height: 26px;
                font-size: 18px;
            }
          
            .more {
                color: #3b97d3;
                font-weight: 900;
                text-transform: uppercase;
                transition: all 0.3s;
            }
        }
    }

    > .title {
      text-align: center;
      user-select: none;
      margin-bottom: 64px;

      h2 {
        font-size: 48px;
        color: #20242b;
      }

      h3 {
        font-weight: 900;
        font-size: 24px;
        color: #3b97d3;
      }

      h4 {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
`
