import React, { Component } from "react"
import StyledFooter from "./styled/StyledFooter"
import { Link } from "react-router-dom"

export default class extends Component {
    render() {
        return <StyledFooter>
            <div className="up" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}><i className="fas fa-arrow-up" /></div>
            <div className="container">
                <div className="grid">
                    <div className="item">
                        <div className="title">Über uns</div>
                        <div className="description">Die scanmetrix.fm GmbH aus Ratingen ist ein junges PropTech-Startup im Bereich der technischen Immobiliendigitalisierung. Mit ihrer gleichnamigen Software versucht sie, den Unternehmensalltag von Handwerkern verschiedenster Gewerke zu digitalisieren und ermöglicht damit den Gebäudebetreibern, ausgeführte Arbeiten und anstehende Prüfungen über ein CAFM-Portal transparent zu verfolgen und zu steuern.</div>
                        <Link to="/" className="logo"><img src="/img/logo_text_white.svg" /></Link>
                    </div>
                    <div className="item">
                        <div className="title">Dienstleistungen</div>
                        <ul>
                            <li><Link to="/services/maintenance">Technische Anlagenwartung</Link></li>
                            <li><Link to="/services/construction">Umbauten und Sanierung</Link></li>
                            <li><Link to="/services/installation">Anlageninstallation</Link></li>
                            <li><Link to="/services/digital">Digitale Gebäudeaufnahme</Link></li>
                            <li><Link to="/services/networking">Dienstleister Networking</Link></li>
                            <li><Link to="/services/fullservice">Full-Service-Paket</Link></li>
                        </ul>
                    </div>
                    <div className="item">
                        <div className="title">Software</div>
                        <ul>
                            {/*<li><a href="#">Die Plattform</a></li>*/}
                            <li><a href="https://handwerk.scanmetrix.io/">Für Handwerker</a></li>
                            <li><a href="https://betreiber.scanmetrix.io/">Für Betreiber</a></li>
                        </ul>
                        <div className="title" style={{ marginTop: 32 }}>Unternehmen</div>
                        <ul>
                            <li><Link to="/about">Über uns</Link></li>
                            <li><Link to="/careers">Karriere</Link></li>
                            <li><Link to="/media">Medien & Downloads</Link></li>
                        </ul>
                    </div>
                    <div className="item">
                        <div className="title">Rechtliches</div>
                        <ul>
                            <li><Link to="/imprint">Impressum</Link></li>
                            <li><Link to="/#contact">Kontakt</Link></li>
                            <li><Link to="/agb">AGB (02/2023)</Link></li>
                            <li><Link to="/privacy-policy">Datenschutzerklärung</Link></li>
                            <li><Link to="/imprint#disclaimer">Haftungsausschluss</Link></li>
                        </ul>
                        <img src="/zia_innovation_logo.svg" className="zia" />
                    </div>
                </div>
                <div className="bottom">
                    <p><i className="far fa-copyright" /> Copyright 2024 scanmetrix.fm GmbH</p>
                    <div className="love">Made with <i className="fas fa-heart" /> in Germany</div>
                    <div className="social">
                        <a href="https://www.instagram.com/scanmetrixfm/"><i className="fa-brands fa-instagram" /></a>
                        <a href="https://www.facebook.com/scanmetrix.fm/"><i className="fa-brands fa-facebook" /></a>
                        <a href="https://www.linkedin.com/company/scanmetrix-fm-gmbh"><i className="fa-brands fa-linkedin" /></a>
                        <a href="https://twitter.com/scanmetrix"><i className="fa-brands fa-twitter" /></a>
                        <a href="https://www.youtube.com/@scanmetrix"><i className="fa-brands fa-youtube" /></a>
                        {/*<a href="#"><i className="fa-brands fa-tiktok" /></a>*/}
                    </div>
                </div>
            </div>
        </StyledFooter>
    }
}
