import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Fade from 'react-reveal/Fade';
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"

const timeline = [
    {
        date: "10 / 2020",
        value: "Unternehmensgründung",
        description: "Ein Konzept wird Wirklichkeit: Die Herren Schreiber & Hull gründen die scanmetrix.fm GmbH in Mülheim an der Ruhr - während der Hochphase der Coronapandemie.",
        icon: "gavel"
    },
    {
        date: "12 / 2020",
        value: "1. Investitionsrunde aus Eigenkapital",
        description: "Durch Aufnahme eines weiteren Gesellschafters wird die erste Investitionsrunde aus Eigenkapital bereitgestellt, um den Aufbau erster Strukturen zu finanzieren.",
        icon: "piggy-bank"
    },
    {
        date: "12 / 2020",
        value: "Jahresabschluss",
        description: <>Das Unternehmen verzeichnet in 2020 trotz Minimalstrukturen einen Gewinn in Höhe von <b>8.877,74 EUR</b>.</>,
        icon: "money-bill-wave"
    },
    {
        date: "01 / 2021",
        value: "Aufbau der Betriebsstrukturen",
        description: "Die Handlungsfähigkeit des Unternehmens wird durch Beschaffung eines Fuhrparks, Geschäftsräumlichkeiten, Lagermöglichkeiten und Infrastruktur sichergestellt.",
        icon: "wrench"
    },
    {
        date: "03 / 2021",
        value: "Beginn des Geschäftsbetriebes",
        description: "Die Abwicklung erster Aufträge im Bereich Facility Management wird sichergestellt. Der Umsatz des Unternehmens steigt durch die Aufnahme der Dienstleistungstätigkeit exponentiell.",
        icon: "flag-checkered"
    },
    {
        date: "04 / 2021",
        value: "Strategischer Zuwachs",
        description: "Udo Witte, ehem. Geschäftsführer der Aalberts Surface Technologies GmbH, tritt dem Unternehmen bei.",
        icon: "user-plus"
    },
    {
        date: "05 / 2021",
        value: "Offizieller Launch des Cockpit",
        description: "Das Kernprodukt der Softwareplattform wird offiziell veröffentlicht: Das scanmetrix Cockpit. Ein cloud-basiertes CAFM mit Fokus auf digitale Kollaboration und Personalentlastung.",
        icon: "laptop"
    },
    {
        date: "06 / 2021",
        value: "Auskauf Gesellschafter",
        description: "Der in 2020 eingetretene Gesellschafter der ersten Finanzierungsrunde wird von den weiteren Gesellschaftern ausgelöst.",
        icon: "money-check"
    },
    {
        date: "07 / 2021",
        value: "Geschäftsführerwechsel",
        description: "Udo Witte übernimmt die Geschäftsführung des Unternehmens und löst damit Herrn Gustav Schreiber, ehem. Geschäftsführer der GMH Recycling GmbH, ab.",
        icon: "user-tie"
    },
    {
        date: "11 / 2021",
        value: "2. Investitionsrunde aus Eigenkapital",
        description: "Zur Erweiterung der internen Betriebsstrukturen und zur Sicherstellung der allgemeinen Handlungsfähigkeit wird eine zweite Investitionsrunde aus Eigenkapital finanziert.",
        icon: "piggy-bank"
    },
    {
        date: "12 / 2021",
        value: "Jahresabschluss",
        description: <>Das Unternehmen verzeichnet in 2021 einen Verlust in Höhe von <b>258.082 EUR</b> und liegt damit 7.8% unter der Verlustprognose.</>,
        icon: "money-bill-wave"
    },
    {
        date: "01 / 2022",
        value: "Wechsel des Hauptsitzes",
        description: "Zur Erweiterung der Geschäftsräume und des Lagerraumes wechselt das Unternehmen zum ersten Januar ihren Sitz an die Mintarder Straße in Mülheim an der Ruhr.",
        icon: "house"
    },
    {
        date: "12 / 2022",
        value: "Offizieller Launch der Mission App",
        description: "Mit scanmetrix Mission veröffentlicht das Unternehmen das zweite Produkt der Plattform; Ein TGA-Aufnahmetool für Sachkundige und Dienstleister mit Fokus auf Wartung.",
        icon: "laptop"
    },
    {
        date: "04 / 2022",
        value: "3. Investitionsrunde aus Eigenkapital",
        description: "Als Beitrag zum Wachstum des Unternehmens wird eine dritte und letzte Investitionsrunde aus Eigenkapital finanziert.",
        icon: "piggy-bank"
    },
    {
        date: "08 / 2022",
        value: "Stetiges Wachstum",
        description: "Neue, große Kunden und Partner können erfolgreich geworben werden und tragen massiv zum Wachstum des Unternehmens bei.",
        icon: "seedling"
    },
    {
        date: "12 / 2022",
        value: "Jahresabschluss",
        description: <>Das Unternehmen verzeichnet in 2022 einen Verlust in Höhe von <b>65.217 EUR</b> und reduziert damit den Verlust im Vorjahresvergleich um kräftige <b>74.7%</b>.</>,
        icon: "money-bill-wave"
    },
    {
        date: "01 / 2023",
        value: "Offizieller Launch des ERP",
        description: "Das dritte Produkt der Plattform, welches insgesamt die meiste Entwicklungszeit in Anspruch genommen hat, wird veröffentlicht; Ein cloud-basiertes ERP für Handwerker und Dienstleister in der Gebäudeservicebranche.",
        icon: "laptop"
    },
    {
        date: "03 / 2023",
        value: "Stetiges Wachstum",
        description: "Das erste Quartal 2023 wird mit einem Überschuss abgeschlossen - dem ersten Mal in der Unternehmensgeschichte.",
        icon: "seedling"
    },
    {
        date: "05 / 2023",
        value: "Launch der neuen Webseite",
        description: "Nebst diverser Werbemaßnahmen, Änderungen an unserem Branding und Messevorbereitungen veröffentlichen wir unsere neue Webseite.",
        icon: "globe"
    },
    {
        date: "06 / 2023",
        value: "Erster Messeauftritt",
        description: "Erstmalig agiert scanmetrix als Aussteller auf einer Messe. Im Juni 2023 finden Sie uns auf der CAFM-Messe im Esperando, Fulda.",
        icon: "screen-users"
    },
    {
        date: "06 / 2023",
        value: "Stetiges Wachstum",
        description: "Das zweite Quartal 2023 wird erneut mit einem Überschuss abgeschlossen - dem zweiten Mal in Folge.",
        icon: "seedling"
    },



    {
        date: "08 / 2023",
        value: "Verstärkung des Software-Teams",
        description: "Das Unternehmen erweitert sein Software-Team durch die Einstellung eines weiteren Entwicklers, um die Produktentwicklung und die technische Leistungsfähigkeit der Plattform weiter auszubauen und zu stärken.",
        icon: "user-plus"
    },
    {
        date: "10 / 2023",
        value: "Strategische Messepräsenz und Kundenakquise",
        description: "Scanmetrix präsentiert sich erfolgreich auf der Expo Real Messe und gewinnt einen neuen, namhaften Full-Service FM Dienstleister, der zur weiteren Expansion und Etablierung der Plattform beiträgt.",
        icon: "screen-users"
    },
    {
        date: "11 / 2023",
        value: "Tausch im Gesellschafterkreis",
        description: "Im November kommt es zu einer Neuordnung im Gesellschafterkreis, bei der ein bisheriger Gesellschafter das Unternehmen verlässt und ein neuer Gesellschafter die Anteile übernimmt, um die strategische Ausrichtung für Bauprojekte und im FM weiter zu festigen.",
        icon: "user-tie"
    },
    {
        date: "01 / 2024",
        value: "Jahresabschluss",
        description: "Das Unternehmen verdoppelt im Geschäftsjahr 2023 seinen Umsatz und setzt damit den positiven Wachstumstrend fort, der seit dem ersten Quartal des Vorjahres anhält.",
        icon: "money-bill-wave"
    },
    {
        date: "04 / 2024",
        value: "Erneute Teilnahme an der Proptech Messe in Hamburg",
        description: "Erstmalig präsentiert sich scanmetrix als Aussteller auf der Proptech Messe in Hamburg. Der Messeauftritt fördert die Markenpräsenz und vertieft den Austausch mit potenziellen Kunden und Partnern im Proptech-Sektor.",
        icon: "screen-users"
    },
    {
        date: "06 / 2024",
        value: "Erweiterte Messepräsenz und Gewinn eines Großkunden",
        description: "Im Juni präsentiert sich scanmetrix.fm sowohl auf der Real Estate Messe in Hannover als auch erneut auf der CAFM-Messe in Fulda. Zusätzlich konnte das Unternehmen einen internationalen Immobilienkonzern als strategischen Kunden zum Aufbau eines eigenen Konzern-FM gewinnen, was den ersten Booster unserer Marktpräsenz mit sich bringt.",
        icon: "screen-users"
    },
    {
        date: "08 / 2024",
        value: "Halbjahreszahlen und Neukundengewinn",
        description: "Das Unternehmen veröffentlicht die Halbjahreszahlen und verzeichnet weiterhin stabiles Wachstum. Zudem konnte ein großer regionaler Kunde im Brandschutz gewonnen werden, was das Vertrauen in die scanmetrix ERP-Lösung weiter unterstreicht.",
        icon: "money-bill-wave"
    },
    {
        date: "09 / 2024",
        value: "Gewinnung eines weiteren Großkunden",
        description: "scanmetrix gewinnt das wohl größte deutsche Logistikunternehmen als Großkunden im Geschäftsbereich Brandschutz.",
        icon: "seedling"
    },
    {
        date: "10 / 2024",
        value: "Umzug des Betriebs nach Ratingen",
        description: "Zur Unterstützung des weiteren Wachstums verlegt das Unternehmen seinen Hauptsitz nach Ratingen und schafft dort erweiterte Kapazitäten für die zukünftige Entwicklung und Betreuung unserer Kunden.",
        icon: "house"
    },
    
]

const AboutUs = styled.div`
    font-size: 22px;
    line-height: 38px;
`

const KeyFacts = styled.div`

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    user-select: none;
    grid-gap: 64px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        width: 64px;
        height: 64px;
        border: 3px solid #3b97d3;
        color: #3b97d3;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        flex-shrink: 0;
        margin-right: 32px;
      }

      .right {
        display: flex;
        flex-direction: column;

        p.title {
          font-weight: bold;
          font-size: 24px;
          text-transform: uppercase;
          color: #20242b;
        }

        p.description {
          font-size: 20px;
          margin-top: 8px;
          line-height: 32px;
        }
      }
    }
  }
`

const Timeline = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
  
    &:after {
        content: " ";
        display: block;
        width: 2px;
        height: 100%;
        background: #20242b;
        position: absolute;
        left: calc(50% - 1px);
        top: 0;


      @media screen and (max-width: 900px) {
          display: none;
      }
    }
  
    .timelineItem {
        width: 50%;
        position: relative;
        box-sizing: border-box;
      
        @media screen and (max-width: 900px) {
            width: 100%;
            margin-left: 0 !important;
            text-align: left !important;
            margin-bottom: 32px;
          
           * {
              text-align: left !important;
           }
          
            .description {
                width: 100% !important;
            }
            
            .block {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
            
            .icon {
                right: unset !important;
                left: 0 !important;
                border: none !important;
                position: relative !important;
                margin-bottom: 24px;
            }
            
            .line {
                display: none !important;
            }
        }
      
        .icon {
            width: 64px;
            height: 64px;
            position: absolute;
            background: #20242b;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            border-radius: 100%;
            right: -36px;
            z-index: 2;
            border: 4px solid white;
        }
      
        .block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            box-sizing: border-box;
            padding-right: 32px;
          
            .date {
                font-weight: bold;
                font-size: 22px;
                margin-bottom: 8px;
            }
          
            .value {
                text-transform: uppercase;
                margin-bottom: 16px;
                color: #3b97d3;
                font-weight: bold;:
            }
          
            .description {
                width: 60%;
                text-align: left;
                align-self: flex-start;
                line-height: 32px;
                margin-bottom: 32px;
            }
          
            div {
                width: 100%;
            }
          
            .line {
                width: 20%;
                height: 2px;
                background: linear-gradient(90deg, #20242b, transparent);
                margin-top: 32px;
                margin-bottom: 48px;
            }
        }
      
        &.left {
            .block {
                text-align: right;
            }
          
            .line {
                background: linear-gradient(-90deg, #20242b, transparent);
            }
          
            .description {
                align-self: flex-end;
                text-align: right;
            }
        }
      
        &.right {
            margin-left: 50%;
          
            .icon {
                right: unset;
                left: -36px;
            }

            .block {
                padding-right: 0;
                padding-left: 32px;
            }
          
            .line {
                align-self: flex-start;
            }
        }
    }
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Über uns" subTitle="Wer wir sind und was uns auszeichnet." image="/img/about.jpg" />
            <Content>
                <div className="content">
                    <h3>Unser Leitbild.</h3>
                    <h2>Was ist scanmetrix?</h2>
                    <AboutUs>
                        Die scanmetrix.fm GmbH aus Ratingen spezialisiert sich auf bundesweite Facility
                        Management Generaldienstleistungen mit
                        Schwerpunkt Technik.
                        Dabei erfolgt die Dokumentation der
                        durchgeführten, anstehenden und geplanten
                        Arbeiten volldigital mit Hilfe der gleichnamigen
                        Softwareplattorm scanmetrix.
                        Unsere Kunden erhalten so digitale,
                        transparente und rund um die Uhr verfügbare
                        Einsicht in alle Bestandsdaten sowie die
                        Möglichkeit, auf Wunsch einzelne CAFM-Tools
                        zu adaptieren, um das Facility- und Property Management im Unternehmen zu steuern.
                        Resultat ist eine nie da gewesene Form der
                        Zusammenarbeit und Kommunikation zwischen
                        Dienstleister und Betreiber, welche eine solide
                        Arbeitsbasis für beide Parteien schafft.
                        Die scanmetrix.fm GmbH fungiert in dieser
                        Struktur als Service- und Baudienstleister für
                        alle Bereiche des Brandschutzes (Wartung der
                        prüfpflichtigen TGA, Feuerwehrlaufkarten,
                        Flucht- und Rettungspläne, Anlageninstallationsowie Errichtung) sowie für naheliegende
                        Themen aus der Arbeitssicherheit, dazu
                        gehören mitunter die Bereiche Lüftungstechnik,
                        Kälte-/Klimatechnik, Sanitärarbeiten und
                        Elektrotechnik.
                    </AboutUs>
                </div>
            </Content>
            <div style={{ backgroundColor: "#eff2f7" }}>
                <Content>
                    <h3>Das Wichtigste auf einen Blick.</h3>
                    <h2>Key Facts</h2>
                    <KeyFacts>
                        <div className="grid">
                            <div className="item">
                                <i className="fa-duotone fa-sparkles" />
                                <div className="right">
                                    <p className="title">Wir sind Startup</p>
                                    <p className="description">Die Gründung in 2020 - und das erste Geschäftsjahr in 2021 - klassifiziert uns als Startup; auch, wenn es sich nicht so anfühlt.</p>
                                </div>
                            </div>
                            <div className="item">
                                <i className="fa-duotone fa-heart" />
                                <div className="right">
                                    <p className="title">Im Herzen sind wir Handwerker</p>
                                    <p className="description">Im Ursprung stammen wir aus dem Handwerk, daher kennen wir die Probleme und Fallstricke unserer Branche nur zu gut.</p>
                                </div>
                            </div>
                            <div className="item">
                                <i className="fa-duotone fa-piggy-bank" />
                                <div className="right">
                                    <p className="title">Finanziert durch Eigenkapital</p>
                                    <p className="description">Wir sind zu 100% durch Eigenkapital finanziert und arbeiten vollständig autark. Wir gehören zu keiner Unternehmensgruppe.</p>
                                </div>
                            </div>
                            <div className="item">
                                <i className="fa-duotone fa-chart-network" />
                                <div className="right">
                                    <p className="title">Wir verstehen uns als Netzwerk</p>
                                    <p className="description">Netzwerken verstehen wir als unsere oberste Priorität; Zögern Sie daher nicht, uns auf Partnerschaft oder Vermittlung anzusprechen.</p>
                                </div>
                            </div>
                            <div className="item">
                                <i className="fa-duotone fa-flag-checkered" />
                                <div className="right">
                                    <p className="title">Klare Zielgruppendefinition</p>
                                    <p className="description">Wir bedienen Unternehmen aus dem Mittelstand und Konzernumfeld, die viele oder große Liegenschaften betreiben.</p>
                                </div>
                            </div>
                            <div className="item">
                                <i className="fa-duotone fa-hand-fist" />
                                <div className="right">
                                    <p className="title">Wir haben hohe Ambitionen</p>
                                    <p className="description">Wir haben erst gerade begonnen und behalten unseren Kurs bei. Mit jedem Tag wachen und verbessern wir uns selbst.</p>
                                </div>
                            </div>
                        </div>
                    </KeyFacts>
                </Content>
            </div>
            <Content>
                <h3 style={{ textAlign: "center" }}>Von Gründung bis heute.</h3>
                <h2 style={{ textAlign: "center", marginBottom: 64 }}>Unsere Geschichte</h2>
                <Timeline>
                    {timeline.map((timelineItem, index) => <div style={{ zIndex: 2, position: "relative" }} key={index}>
                        <Fade>
                            <div className={`timelineItem ${index % 2 === 0 ? "right" : "left"}`}>
                                <div className="icon">
                                    <i className={`fal fa-${timelineItem.icon}`} />
                                </div>
                                <div className="block">
                                    <div className="line" />
                                    <div className="date">{timelineItem.date}</div>
                                    <div className="value">{timelineItem.value}</div>
                                    <div className="description">{timelineItem.description}</div>
                                </div>
                            </div>
                        </Fade>
                    </div>)}
                </Timeline>
            </Content>
            <Footer />
        </>
    }
}
