import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 128px 0;
  
  #join-widget {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-top: -36px;
      margin-bottom: -36px;
  }
  
  .container {
    width: 100%;
    box-sizing: border-box;
    max-width: 1500px;
    position: relative;
    padding: 0 64px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }

    h2 {
      font-size: 48px;
      color: #20242b;
      user-select: none;
      margin-bottom: 64px;
    }

    h3 {
      font-weight: 900;
      font-size: 24px;
      color: #3b97d3;
      user-select: none;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      user-select: none;
    }
    
    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        user-select: none;
        grid-gap: 64px;
      
        @media screen and (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
      
        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
          
            i {
                width: 64px;
                height: 64px;
                border: 3px solid #3b97d3;
                color: #3b97d3;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 28px;
                flex-shrink: 0;
                margin-right: 32px;
            }
          
            .right {
                display: flex;
                flex-direction: column;
              
                p.title {
                    font-weight: bold;
                    font-size: 24px;
                    text-transform: uppercase;
                    color: #20242b;
                }
              
                p.description {
                    font-size: 20px;
                    margin-top: 8px;
                    line-height: 32px;
                }
            }
        }
    }
  }
`
