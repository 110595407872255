import React, { Component } from "react";
import StyledContent from "./styled/StyledContent";
import SuccessStoriesPage from "./styled/StyledContent";

export default class SuccessStoriesWrapper extends Component {
  render() {
    return (
      <StyledContent>
        <div className="Container">
          {this.props.children}
          <SuccessStoriesPage />
        </div>
      </StyledContent>
    );
  }
}
