import React, { Component } from "react"
import StyledCallToAction from "./styled/StyledCallToAction"

export default class extends Component {
    render() {
        return <StyledCallToAction>
            <img src="/img/worldmap.svg" />
            <div className="container">
                <p className="phone">+49 2102 56 56 930</p>
                <p className="title">Schon überzeugt?</p>
                <p className="description">Vereinbaren Sie ein persönliches Gespräch oder rufen Sie uns direkt an.</p>
                <div className="button" onClick={() => {
                    const section = document.querySelector( '#contact' );
                    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
                }}>Kontakt aufbauen</div>
            </div>
        </StyledCallToAction>
    }
}
