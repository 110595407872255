import styled from "styled-components";

export default styled.div`
  background: #eff2f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;
  
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }

    .news {
        display: grid;
        grid-gap: 48px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;

        @media screen and (max-width: 1499px) {
            grid-template-columns: 1fr 1fr;
        }
      
        @media screen and (max-width: 720px) {
            grid-template-columns: 1fr;
        }
      
        .item {
            background: white;
            border-radius: 5px;
            padding: 32px;
            box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.15);
            position: relative;
          
            .date {
                background: #3b97d3;
                user-select: none;
                height: 32px;
                color: white;
                padding: 0 12px;
                position: absolute;
                left: -12px;
                display: flex;
                font-size: 18px;
                align-items: center;
                justify-content: center;
                top: 16px;
                border-radius: 5px;
            }
          
            .title {
                font-weight: 900;
                font-size: 24px;
                color: #20242b;
                margin: 16px 0;
            }
          
            .description {
                font-size: 18px;
                line-height: 28px;
            }
          
            .more {
                color: #3b97d3;
                font-weight: 900;
                margin-top: 16px;
                user-select: none;
                cursor: pointer;
                transition: color 0.3s;
              
                &:hover {
                    color: #20242b;
                }
              
                i {
                    margin-right: 4px;
                }
            }
          
            img {
                height: 96px;
                width: calc(100% + 64px);
                margin-left: -32px;
                object-fit: cover;
                object-position: center;
                user-select: none;
                -webkit-user-drag: none;
            }
        }
    }

    > .title {
      text-align: center;
      user-select: none;
      margin-bottom: 64px;

      h2 {
        font-size: 48px;
      }

      h3 {
        font-weight: 900;
        font-size: 24px;
        color: #3b97d3;
      }

      h4 {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
      }
    }
  }
`
