import React from "react";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile }) => {

const slideStyle = {
    backgroundColor: '#20242b',
    overflow: 'hidden',  
  };
  
const titleStyle = {
  whiteSpace: 'normal', 
  color: '#D6DFFF',
  fontWeight: 100,
  };

const subtitleStyle = {
  fontSize: '20px', // Adjust the font size as needed
  color: '#D6DFFF', // Set the text color to black
  fontWeight: 900,
};

const mobileResponsive = useMediaQuery("(max-width:1024px)");
// const mobileResponsive1 = useMediaQuery("(max-width:700px)");

if (mobileResponsive) {
  titleStyle.fontSize = '15px';
  subtitleStyle.fontSize = '18px';
}

// if (mobileResponsive1) {
//   titleStyle.fontSize = '15px';
//   subtitleStyle.fontSize = '18px';
// }


return (
    <div>
      <AutoRotatingCarousel
        label="Schließen"
        open={handleOpen.open}
        onClose={() => setHandleOpen({ open: false })}
        onStart={() => setHandleOpen({ open: false })}
        autoplay={true}
        interval={10000}
        mobile={isMobile}
        style={{ position: "absolute" }}
      >
      <Slide
        media={<img src="https://img.youtube.com/vi/tREN3Z2Wf3E/maxresdefault.jpg" />}
        mediaBackgroundStyle={{ backgroundColor: '#FFFFFF' }}
        style={slideStyle}
        title={<div style={titleStyle}>"It´s a super product with professional support team. I can´t wait to see the future 
         features."</div>}
        subtitle={<div style={subtitleStyle}>David Warner | CEO</div>}
        />
        
     <Slide
        media={<img src="https://img.youtube.com/vi/tREN3Z2Wf3E/maxresdefault.jpg" />}
        mediaBackgroundStyle={{ backgroundColor: '#FFFFFF' }}
        style={slideStyle}
        title={<div style={titleStyle}>"It´s a super product with professional support team. I can´t wait to see the future 
         features."</div>}
        subtitle={<div style={subtitleStyle}>David Warner | CEO</div>}
        />
        

       <Slide
        media={<img src="https://img.youtube.com/vi/tREN3Z2Wf3E/maxresdefault.jpg" />}
        mediaBackgroundStyle={{ backgroundColor: '#FFFFFF' }}
        style={slideStyle}
        title={<div style={titleStyle}>"It´s a super product with professional support team. I can´t wait to see the future 
         features."</div>}
        subtitle={<div style={subtitleStyle}>David Warner | CEO</div>}
        />
      </AutoRotatingCarousel>
    </div>
    
  )
}

export default AutoRotatingCarouselModal;


