import styled from "styled-components"

export default styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 160px 0;
    justify-content: center;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    
    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 64px;
        max-width: 1500px;

      @media screen and (max-width: 1024px) {
        padding: 0 48px;
      }


      > .title {
        text-align: center;
        user-select: none;
        margin-bottom: 64px;

        h2 {
          font-size: 48px;
          color: #20242b;
        }

        h3 {
          font-weight: 900;
          font-size: 24px;
          color: #3b97d3;
        }
      }
      
        .questions {
            width: 100%;
            display: grid;
            grid-gap: 64px;
            grid-template-columns: 1fr 1fr;
          
            @media screen and (max-width: 1024px) {
                grid-template-columns: 1fr;
            }
          
            .question {
                display: flex;
                flex-direction: column;
              
                .title {
                    font-weight: 600;
                    margin-bottom: 16px;
                    font-size: 26px;

                  &::after {
                    display: block;
                    content: " ";
                    height: 2px;
                    width: 48px;
                    background: #3b97d3;
                    margin-top: 16px;
                  }
                }
              
                .description {
                    line-height: 32px;
                    font-size: 20px;
                }
            }
        }
    }
`
