import styled from "styled-components";

export default styled.button`
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 22px;
    color: white;
    background-color: #3b97d3;
    border: none;
    cursor: pointer;
`;
