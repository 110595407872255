import React, { Component } from "react"
import Navigation from "../Start/modules/Navigation";
import Header from "../Start/modules/SmallHeader"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"

export default class extends Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://join.com/api/widget/bundle/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZXR0aW5ncyI6eyJzaG93Q2F0ZWdvcnlGaWx0ZXIiOnRydWUsInNob3dMb2NhdGlvbkZpbHRlciI6dHJ1ZSwic2hvd0VtcGxveW1lbnRUeXBlRmlsdGVyIjp0cnVlLCJsYW5ndWFnZSI6ImRlIiwiam9ic1BlclBhZ2UiOjI1fSwiam9icyI6e30sImRlc2lnbiI6eyJzaG93TG9nbyI6dHJ1ZSwic2hvd0xvY2F0aW9uIjp0cnVlLCJzaG93RW1wbG95bWVudFR5cGUiOnRydWUsInNob3dDYXRlZ29yeSI6dHJ1ZSwiY29sb3JzIjp7IndpZGdldCI6eyJiYWNrZ3JvdW5kIjoiI2VmZjJmNyIsImZpbHRlckJvcmRlciI6IiNENEQ0RDgiLCJwYWdpbmF0aW9uIjoiIzNCOTdEMyJ9LCJqb2JDYXJkIjp7InNoYWRvdyI6IiNENEQ0RDgiLCJiYWNrZ3JvdW5kIjoiI0ZGRkZGRiIsInByaW1hcnlUZXh0IjoiIzIwMjQyYiIsInNlY29uZGFyeVRleHQiOiIjNTI1MjVCIn19fSwidmVyc2lvbiI6MiwiY29tcGFueVB1YmxpY0lkIjoiYzliMjliMjc3N2Y3ZWY5ZDg4OGRlNGFiMDc5MTg4YWMiLCJpYXQiOjE2ODU2MTExNTcsImp0aSI6ImVlNjc4ODAxLWFlYTktNDdhOC05MDQ1LTVhM2M0ZGRjYjc2ZSJ9.Xz00AOeItIKU3Dm3Q6tkQiQNuqoSn1ov13Vjgb1_sxs";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return <>
            <Navigation />
            <Header title="Arbeiten bei scanmetrix" subTitle="Offene Stellenanzeigen & Jobs." image="/img/careers.jpg" />
            <Content>
                <h3>Wir lieben Teamkultur.</h3>
                <h2>Warum mit uns arbeiten?</h2>
                <div className="grid">
                    <div className="item">
                        <i className="fa-duotone fa-laptop" />
                        <div className="right">
                            <p className="title">Modernste Arbeitsausstattung</p>
                            <p className="description">Du erhältst von uns modernste Tools, die dich und deine Arbeit fördern und stets ans Ziel bringen.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-heart" />
                        <div className="right">
                            <p className="title">Ein Herz für Freizeit</p>
                            <p className="description">Wir nehmen Rücksicht auf deine persönliche Anliegen. Gleitzeit, Home Office und Vertrauensarbeit sind bei uns Standard.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-piggy-bank" />
                        <div className="right">
                            <p className="title">Faire Vergütung</p>
                            <p className="description">Du erhältst eine leistungsgerechte Vergütung, die deine Arbeitsqualität und Motivation wiederspiegelt.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-chalkboard-user" />
                        <div className="right">
                            <p className="title">Weiterbildung</p>
                            <p className="description">Durch interne und externe Schulungen und Weiterbildungen fördern wir dich und deine Persönlichkeitsentwicklung.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-island-tropical" />
                        <div className="right">
                            <p className="title">Firmenevents</p>
                            <p className="description">Darts, Airhockey, gemeinsame Weihnachtsfeier oder doch zusammen Pizza bestellen? Abwechslung muss sein!</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-face-sunglasses" />
                        <div className="right">
                            <p className="title">Die weltbeste Mannschaft</p>
                            <p className="description">Ein familiäres, gemeinschaftliches Team, das immer an deiner Seite spielt. Respekt und Kooperation stehen bei uns an erster Stelle.</p>
                        </div>
                    </div>
                </div>
            </Content>
            <div style={{ backgroundColor: "#eff2f7" }}>
                <Content>
                    <h3>Vielleicht ist für dich was dabei?</h3>
                    <h2>Offene Stellenanzeigen</h2>
                    <div id="join-widget"></div>
                </Content>
            </div>
            <Footer />
        </>
    }
}
