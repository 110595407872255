import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"
import { Link } from "react-router-dom"


const Text = styled.div`
    font-size: 22px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Umbauten und Sanierungen" subTitle="Baumaßnahmen aus einer Hand." image="/img/construction.jpg" />
            <Content>
                <div className="content">
                    <h3>Qualität in jedem Detail.</h3>
                    <h2>Bei uns finden Sie den idealen Dienstleister für die Einrichtung und Planung Ihrer Bauprojekte. Wir übernehmen zuverlässig Umbauten und Sanierungen für Sie. Vertrauen Sie auf unsere vielfältigen Leistungen und langjährige Erfahrung in der Branche. Unser engagiertes Team steht Ihnen zur Seite, um Ihre Projekte erfolgreich und zuverlässig umzusetzen.</h2>
                    <div className="grid">
                    <div className="item">
                        <i className="fa-duotone fa-pen-ruler" />
                        <div className="right">
                            <p className="title">Planung und Projektierung</p>
                            <p className="description">Unsere Experten bieten professionelle Planung und Projektierung bei Umbauten und Sanierungen, um Ihre Vorhaben effizient und erfolgreich umzusetzen.</p> 
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-pipe-circle-check" />
                        <div className="right">
                            <p className="title">Rohrarbeiten</p>
                            <p className="description">Unser Team führt präzise und zuverlässige Rohrarbeiten für Umbauten und Sanierungen durch, um Ihre Projekte erfolgreich zu realisieren.</p>  
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-trowel" />
                        <div className="right">
                            <p className="title">Fliesenlegerarbeiten</p>
                            <p className="description">Unsere erfahrenen Fliesenleger erledigen präzise und ästhetische Fliesenarbeiten, um Ihre Räumlichkeiten in neuem Glanz erstrahlen zu lassen.</p> 
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-paint-roller" />
                        <div className="right">
                            <p className="title">Malerarbeiten und Trockenbau</p>
                            <p className="description">Unsere erfahrenen Fachleute erledigen Malerarbeiten und Trockenbau mit höchster Präzision für Ihre Umbauten und Sanierungsprojekte.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-heat" />
                        <div className="right">
                            <p className="title">Errichtung von Heizungs- und Sanitäranlagen</p>
                            <p className="description">Unser erfahrenes Team kümmert sich zuverlässig um die Errichtung von Heizungs- und Sanitäranlagen, um Ihren Komfort und Ihre Bedürfnisse optimal zu erfüllen.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-duotone fa-vent-damper" />
                        <div className="right">
                            <p className="title">Errichtung von Lüftungskanälen</p>
                            <p className="description">Wir realisieren professionell die Errichtung von Lüftungskanälen, um eine effiziente und gesunde Raumluftzirkulation zu gewährleisten.</p>
                        
                        </div>
                        </div>

                        <div className="item">
                        <i className="fa-duotone fa-air-conditioner" />
                        <div className="right">
                            <p className="title">Errichtung von Klimaanlagen</p>
                            <p className="description">Unser Team kümmert sich fachgerecht um die Errichtung von Klimaanlagen für Umbauten und Sanierungen, um ein angenehmes Raumklima zu schaffen.</p> 
                        </div>
                    </div>

                    <div className="item">
                        <i className="fa-regular fa-vacuum" />
                        <div className="right">
                            <p className="title">Asbestbeseitigung</p>
                            <p className="description">Unsere spezialisierten Experten führen Asbestbeseitigung sicher und fachgerecht durch, um Ihre Räumlichkeiten von gesundheitsgefährdenden Materialien zu befreien.</p>
                        </div>
                    </div>

                    <div className="item">
                        <i className="fa-solid fa-hammer-crash" />
                        <div className="right">
                            <p className="title">Abbrucharbeiten</p>
                            <p className="description">Unser Team führt Abbrucharbeiten für Umbauten und Sanierungen mit hoher Präzision und Sicherheit durch, um Platz für Ihre neuen Projekte zu schaffen.</p> 
                        </div>
                    </div>


                    </div>

                    <div className="grid2">
                    <div className="item">
                        <i className="fa-duotone fa-window-frame" />
                        <div className="right">
                            <p className="title">Einbau von Fenstern und Bauelementen</p>
                            <p className="description">Wir kümmern uns um den fachgerechten Einbau von Fenstern und ähnlichen Elementen, um Ihre Umbauten und Sanierungen perfekt abzuschließen.</p> 
                        </div>
                    </div>

                    <div className="item">
                        <i className="fa-regular fa-fire" />
                        <div className="right">
                            <p className="title">Brandabschottungen gemäß DIN 18232</p>
                            <p className="description">Unser kompetentes Team sorgt für fachgerechte Brandabschottungen gemäß DIN 18232 bei Umbauten und Sanierungen, um Ihre Sicherheitsstandards zu erfüllen.</p>
                        </div>
                    </div>





                    </div>
                   
                </div>
            </Content>
            <Footer />
        </>
    }
}
