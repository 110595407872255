import React, { Component } from "react"
import StyledFAQ from "./styled/StyledFAQ"

export default class extends Component {
    render() {
        return <StyledFAQ id="faq-list">
            <div className="container">
                <div className="title">
                    <h3>Wissenswertes.</h3>
                    <h2>Häufig gestellte Fragen</h2>
                </div>
                <div className="questions">
                    <div className="question">
                        <p className="title">Was ist Ihre Strategie? Warum sollte ich scanmetrix.fm als Dienstleister in Betracht ziehen?</p>
                        <p className="description">Wir verstehen uns als zentraler Ansprechpartner für alle Ihre Anliegen im Facility Management. Als mittelständisches Unternehmen punkten wir durch die Möglichkeit, agil und flexibel zu arbeiten. Dies sichert Ihnen eine noch transparentere, effizientere Abwicklung als dem Industrie-Standard zu.</p>
                    </div>
                    <div className="question">
                        <p className="title">Worin unterscheiden Sie sich von gängigen FM-Dienstleistern?</p>
                        <p className="description">Unser Ansatz, durch Digitalisierung und optimale Effizienz eine noch nachhaltigere Dienstleistung zu erbringen, hat sich stets bewährt. Wir legen großen Wert darauf, Sie als Partner - nicht als Kunde - aufzubauen und stets vertrauensvoll zu betreuen. Sie werden niemals an eine Hotline verwiesen.</p>
                    </div>
                    <div className="question">
                        <p className="title">Wo bzw. in welchen Regionen erbringen Sie Ihre Dienstleistungen?</p>
                        <p className="description">Alle angebotenen Dienstleistungen werden bundesweit erbracht. Durch unser dezentrales Personal- und Dienstleisternetz haben wir die Möglichkeit, Ihre Liegenschaften ortsunabhängig nachhaltig zu bedienen. Regionale Lücken schließen wir durch den Einsatz geschulter, sachkundiger Subunternehmen.</p>
                    </div>
                    <div className="question">
                        <p className="title">Was verstehen Sie unter Digitalisierung meiner Liegenschaften?</p>
                        <p className="description">Es versteht sich als unsere Inklusivleistung, alle erbrachten Dienste in Ihren Liegenschaften vollständig zu digitalisieren. Konkret bedeutet dies, dass Sie auch nach vollendeter Leistung einen verwendbaren Datensatz in Form eines Online-Portals erhalten, um alle Ausführungen transparent nachzuvollziehen.</p>
                    </div>
                    <div className="question">
                        <p className="title">Wie funktioniert mein Wechsel zu scanmetrix.fm?</p>
                        <p className="description">Nach erfolgreicher Abstimmung über die Rahmenbedingungen unserer Zusammenarbeit erhalten Sie Zugriff auf unser Online-Portal, in welchem Sie alle Ihnen vorliegenden Daten zu Ihren Liegenschaften erfassen. Die daraus entstehenden Dokumentations- & Datenlücken füllen wir dann im Zuge unserer Dienstleistung in digitaler Form.</p>
                    </div>
                    <div className="question">
                        <p className="title">Wie bzw. zu welchen Zeiten kann ich Sie erreichen?</p>
                        <p className="description">Unsere regulären Geschäftszeiten sind Montag bis Donnerstag 7 Uhr bis 17 Uhr und Freitag 7 Uhr bis 14:30 Uhr. Jeder Kunde erhält zudem einen persönlichen Ansprechpartner, der auch außerhalb der Geschäftszeiten für dringende Angelegenheiten zu erreichen ist. Eingehende Tickets werden rund um die Uhr bearbeitet.</p>
                    </div>
                </div>
            </div>
        </StyledFAQ>
    }
}
