import React from 'react'
import { useQuery, gql } from "@apollo/client";
import { Link } from 'react-router-dom'; // Import Link
import "./styled/StyledAllBlogsPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faPeopleGroup, faBirthdayCake, faUserGraduate, faVideo, faBuildingFlag, faIdBadge } from '@fortawesome/free-solid-svg-icons';

const ASSETS_QUERY = gql`
  query Assets {
    blogposts(orderBy: date_DESC) {
      id
      color{
        hex
      }
      title
      description
      icon
      shortDescription
      date
    }
  }
`;

const Index = () => {

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(originalDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const { data, loading, error } = useQuery(ASSETS_QUERY);
  
  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  // const limitedData = data.blogposts.slice(0, 3);

  // single card
  // const filteredData = data.blogposts.filter((_, index) => index !== 0 && index !== 3 && index !== 5 && index !== 6 && index !== 7 && index !== 8  && index !== 9 && index !== 2 && index !== 4);

  // ALL CARDS except 1st and 4th
  const filteredData = data.blogposts

  // card 2 ,4 and 5 is displayed
  // const filteredData = data.blogposts.filter((_, index) => index !== 0 && index !== 2 && index !== 5 && index !== 6 && index !== 7 && index !== 8);


  return (
    <section className='general' >

    <div className='container-new' >
      
       <div className="title-main">
          <h1 className='h1' >Aktuelle Blogposts</h1>
          <p className='p' >
            Erfahre mehr zu technischem Facility Management, <br /> Gebäudemanagement, unseren Produkten und vielem mehr.
          </p>
      </div>
      
     <section className='services'>
        {filteredData.map((blogpost) => (
          <div className='service' key={blogpost.id}>

            <Link to={`/blog/${blogpost.id}`} className="link-style"> {/* here blogpost.id is from IndividuellBlog */}
            <section className='top'>
            <p className='icons' ><i className={`fa-duotone fa-${blogpost.icon}`} /></p>
            <p className='title1'>{blogpost.title}</p>
            <p className='shortdescription' > {blogpost.shortDescription}</p>
            {/* <p className='shortdescription' > {blogpost.shortDescription.slice(0,100)+ "...."}</p> */}
            </section>

            <section className='bottom' >
            <p className='date'>{formatDate(blogpost.date)}</p>
            <button className="more">weiterlesen <i className="arrow fas fa-arrow-right" /></button>
            </section>

            </Link>
          </div>
        ))}
      </section>
      
    </div>

    </section>
  )
}

export default Index





