import React, { Component } from "react"
import Navigation from "../Start/modules/Navigation";
// import Footer from "../Start/modules/Footer"
import IndividuellBlogPost from "./modules/Content/IndividuellBlogPost";


export default class extends Component {
    render() {
        return <>
            <Navigation />
            <IndividuellBlogPost />
        </>
    }
}


