import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"

import { Link } from "react-router-dom"


const Text = styled.div`
    font-size: 22px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Digitale Gebäudeaufnahme" subTitle="Der digitale Zwilling für Ihr Gebäude." image="/img/digital.jpg" />
            <Content>
                <div className="content">
                    <h3>Die Zukunft im Fokus: Präzise, digital, effizient.</h3>
                    <h2>Nutzen Sie die Digitalisierung für eine effiziente Gebäudeaufnahme und vereinfachen Sie Ihren Alltag mithilfe von CAFM-Systemen und QR-Codes. Diese sind nur einige der vielfältigen Vorteile, die durch die Digitalisierung entstehen. Erhalten Sie einen umfassenden Überblick über Ihre Gebäude und optimieren Sie Ihre Arbeitsabläufe durch moderne Technologien. Vertrauen Sie auf unsere Lösungen, um Ihre Prozesse effektiv zu gestalten und Zeit sowie Ressourcen zu sparen.</h2>
                    

                    <div className="grid">
                    <div className="item">
                        <i className="fa-solid fa-server" />
                        <div className="right">
                            <p className="title">Vollumfängliches CAFM-System</p>
                            <p className="description">Unser vollumfängliches CAFM-System bietet Ihnen eine umfassende Lösung für die Verwaltung und Optimierung Ihrer gebäudetechnischen Prozesse</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-qrcode" />
                        <div className="right">
                            <p className="title">Inventarisierung mittels QR-Codes</p>
                            <p className="description">Wir ermöglichen Ihnen eine einfache und effiziente Inventarisierung durch den Einsatz von QR-Codes-Aufklebern.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-book-blank" />
                        <div className="right">
                            <p className="title">Transparente Abbildung zur Steuerung, Kontrolle und Budgetierung</p>
                            <p className="description">Unsere Lösungen bieten eine transparente Abbildung, die Ihnen bei der Steuerung, Kontrolle und Budgetierung Ihrer Projekte hilft.</p>
                        </div>
                    </div>
                    
                    <div className="item">
                        <i className="fa-regular fa-person-digging" />
                        <div className="right">
                            <p className="title">Genaue Verortung der Bauteile und Komponenten</p>
                            <p className="description">Wir ermöglichen eine präzise Verortung von Bauteilen und Komponenten, um eine effiziente Wartung und Instandhaltung zu gewährleisten.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-regular fa-scanner-touchscreen" />
                        <div className="right">
                            <p className="title">Schnelles Identifizieren und Melden von Störungen und Mängeln</p>
                            <p className="description">Durch unser System können Störungen und Mängel schnell identifiziert und gemeldet werden, um zeitnahe Reparaturen zu ermöglichen.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-regular fa-desktop" />
                        <div className="right">
                            <p className="title">Digitale Ablage der Dokumentation</p>
                            <p className="description">Wir bieten die Möglichkeit, Daten und Prüfberichte digital abzulegen, um eine effiziente und gut organisierte Betreiberdokumentation zu gewährleisten.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-regular fa-paper-plane" />
                        <div className="right">
                            <p className="title">Papiereinsparung bis zu 99%</p>
                            <p className="description">Durch die Digitalisierung ermöglichen wir eine Papiereinsparung von bis zu 99% zum Beitrag der Erreichung Ihrer ESG-Ziele.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-laptop-code" />
                        <div className="right">
                            <p className="title">Kompatibel mit bestehender Software durch hohe Schnittstellenkompatibilität</p>
                            <p className="description">Unsere Lösung ist hochgradig kompatibel mit bestehender Software aufgrund ihrer hohen Schnittstellenkompatibilität, was eine reibungslose Integration ermöglicht.</p>
                        </div>
                    </div>
                    <div className="item">
                        <i className="fa-solid fa-house-laptop" />
                        <div className="right">
                            <p className="title">Erstaufnahme von Gebäuden und Anlagen ohne Bestandsdokumentation</p>
                            <p className="description">Mit unserer Erstaufnahme von Gebäuden und Anlagen ohne Bestandsdokumentation schaffen wir eine zuverlässige Grundlage für Ihre zukünftige Planung und Verwaltung.</p>
                        </div>
                    </div>

                    </div>
                   
                </div>
            </Content>
            <Footer />
        </>
    }
}
