import styled from "styled-components"

export default styled.div`
    width: 100%;

    label {
        height: 16px;
        font-size: 20px;
        font-weight: 500;
        user-select: none;

        i {
            color: #3b97d3;
            margin-left: 4px;
            font-size: 10px;
            vertical-align: top;
            margin-top: 4px;
        }
    }

    input, textarea {
      font-size: 20px;
        font-family: inherit;
        width: 100%;
        height: 48px;
        margin-top: 8px;
        outline: 0;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin-bottom: 0;
        box-sizing: border-box;
        padding: 0 16px;
        font-weight: 500;
        transition: all 0.3s;

        &:focus {
            border-color: #3b97d3;
        }
    }

    textarea {
        height: 128px;
        resize: none;
        padding-top: 16px;
    }
`
