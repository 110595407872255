import React, { useEffect } from "react"

import { useLocation } from "react-router-dom"
import Navigation from "./modules/Navigation"
import Header       from "./modules/Header"
import EmbedYouTubeChannel from "./modules/EmbedYouTubeChannel"
import Customers    from "./modules/Customers"
import Testimonial from "./modules/TestimonialCustomer/Testimonial"
import NewCardIndex from "./modules/NewCardTranslate/NewCard"

import News         from "./modules/News"
import Counters     from "./modules/Counters"
import Team         from "./modules/Team"
import CallToAction from "./modules/CallToAction"
import YouTube      from "./modules/YouTube"
import FAQ          from "./modules/FAQ"
import BlogHomePage from "./modules/BlogHomePage"
import Services     from "./modules/Services"
import Contact      from "./modules/Contact"
import Footer       from "./modules/Footer"


export default function() {
    const { pathname, hash, key } = useLocation()

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView()
                }
            }, 0)
        }
    }, [pathname, hash, key])

    return <>
        <Navigation />
        <Header />
        <Services />
        <Counters />
        <News />
        <Customers />
        {/*<Testimonial />
        <NewCardIndex />*/}

        <CallToAction />
        <Team />
        <YouTube />
        {/*<EmbedYouTubeChannel />*/}
        <FAQ />
        {/*<BlogHomePage />*/}
        <Contact />
        <Footer />
    </>
}
