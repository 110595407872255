import React, { Component } from "react"
import StyledContent from "./styled/StyledContent"

export default class extends Component {
    render() {
        return <StyledContent>
            <div className="container">
                {this.props.children}
            </div>
        </StyledContent>
    }
}
