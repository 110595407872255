import React, { Component } from "react"
import StyledHeader from "./styled/StyledHeader"
// import Navigation from "../../../Start/modules/Navigation"

export default class extends Component {
    render() {
        return <StyledHeader image={this.props.image}>
            <div className="container">
                {/* <Navigation /> */}
                <h2>{this.props.subTitle}</h2>
                <h1>{this.props.title}</h1>
            </div>
        </StyledHeader>
    }
}
