import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 128px 0;
  background-image: linear-gradient(0deg,rgb(239 242 247 / 90%),rgb(255 255 255)),url(/img/contact.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 80px;
  
  .arch {
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
      border: 64px solid #3b97d3;
      width: 1700px;
      height: 1700px;
      border-radius: 100%;
      display: none; //todo
  }
  
  .container {
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 64px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
      grid-template-columns: 1fr;
      
      h4 {
        margin: 0 !important;
      }
    }
    
    .contacts {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        font-size: 20px;
        user-select: none;
        font-weight: 500;
        white-space: nowrap;
        flex-wrap: wrap;
      
      @media screen and (max-width: 1024px) {
        width: 100%;
        
        div:nth-child(3) {
          display: none;
        }
      }
        
        i {
            color: #3b97d3;
            margin-right: 16px;
        }
    }
    
    .titles {
        display: flex;
        flex-direction: column;
        width: 100%;


      h2 {
        font-size: 48px;
        color: #20242b;
      }

      h3 {
        font-weight: 900;
        font-size: 24px;
        color: #3b97d3;
      }

      h4 {
        max-width: 700px;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
      }
    }
    
    .form {
        display: grid;
        grid-auto-flow: row;
        background: white;
        grid-gap: 24px;
        margin-top: 32px;
      border-radius: 5px;
      padding: 32px;
      box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.15);


      @media screen and (max-width: 1024px) {
        margin-bottom: 24px;
      }
      
      .button {
          color: white;
          background: #3b97d3;
          width: 100%;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          user-select: none;
          cursor: pointer;
          transition: background 0.3s;
          font-weight: 500;

          &:hover {
              background: #20242b;
          }
        
          i {
              margin-right: 8px;
          }
      }
      
      h4 {
          text-transform: uppercase;
          font-size: 22px;
        user-select: none;
      }
    }
  }
`
