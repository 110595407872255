import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 128px 0;
  font-size: 20px;
  text-align: center;
  
  .container {
    width: 100%;
    box-sizing: border-box;
    max-width: 1500px;
    position: relative;
    padding: 0 64px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
    li {
        padding-left: 16px;
        margin: 8px 0;
        margin-left: 16px;
    }

    ul {
        margin: 16px 0;
    }

    h2 {
      font-size: 48px;
      color: #20242b;
      user-select: none;
      margin-bottom: 32px;
    }

    h3 {
      font-weight: 900;
      font-size: 24px;
      color: #3b97d3;
      user-select: none;
    }

    h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      user-select: none;
      margin: 16px 0;
    }
    
    h5 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 20px;
    }
    
    h6 {
        margin: 12px 0;
        font-weight: 500;
        font-size: 18px;
    }
  }
`
