import React, { Component } from "react"
import Footer from "../Start/modules/Footer"
import Content from "./modules/Content"
import styled from "styled-components"
import Header from "../Start/modules/SmallHeader"
import Navigation from "../Start/modules/Navigation"


import { Link } from "react-router-dom"

const Text = styled.div`
    font-size: 22px;
    line-height: 38px;
`

export default class extends Component {
    render() {
        return <>
            <Navigation />
            <Header title="Das Rundumpaket für Ihr FM" subTitle="Full-Service-Paket" image="/img/fullservice.jpg" />
            <Content>
                <div className="content">
                    <h3>Die „eierlegende Wollmilchsau” für Ihre TGA.</h3>
                    <h2>Wenn Sie einen umfassenden Dienstleister suchen, der Ihre Gewerke sowohl regional als auch bundesweit abdeckt - und das auf digitalem Weg - dann sind Sie bei uns genau richtig. Unsere Partnerschaft mit einem vollständig digitalisierten FM-Dienstleister sowie unserer betriebseigenen Belegschaft ermöglicht uns, einen ganzheitlichen Wartungs- und Betreuungsumfang zu gewährleisten. Sie können auf unsere Unterstützung zählen, um Ihre Projekte erfolgreich umzusetzen und auch komplexere Anforderungen nachhaltig zu erfüllen.</h2>
                </div>
            </Content>
            <Footer />
        </>
    }
}
