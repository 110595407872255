import styled from "styled-components"

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0;
  background: #eff2f7;
  
  .career {
      text-align: center;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding-top: 96px;
      margin-top: 128px;
      user-select: none;

  .kununu {
      margin-top: 24px;
      
      .stars {
          font-size: 24px;  
          color: #3b97d3;
          display: flex;
          justify-content: center;
        
          i {
              margin: 0 4px;
          }
      }
    
      p {
          font-size: 20px;
          margin-top: 12px;
          
          a {
              font-weight: 900;
              color: #3b97d3;
              text-decoration: none;
          }
      }
  }
    
    h2 {
      font-size: 40px;
      color: #20242b;
      
      i {
          color: #3b97d3;
          margin-right: 8px;
          font-size: 32px;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 20px;
      color: #20242b;
    }
    
    .button {
      background: #20242b;
      color: white;
      font-size: 20px;
      height: 64px;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      margin-top: 32px;
      display: inline-flex;
      font-weight: 900;
      text-decoration: none;
      text-transform: uppercase;
      transition: opacity 0.3s;
      
      &:hover {
          opacity: 0.75;
      }
      
      i {
          margin-right: 8px;
      }
    }
  }
  
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
    .title {
        text-align: center;
        user-select: none;
        margin-bottom: 64px;
      
        h2 {
            font-size: 48px;
            color: #20242b;
        }
      
        h3 {
            font-weight: 900;
            font-size: 24px;
            color: #3b97d3;
        }
      
        h4 {
            max-width: 700px;
            text-align: center;
            margin: 0 auto;
            font-size: 22px;
            font-weight: 500;
            line-height: 32px;
        }
    }
    
      .people {
        /*display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 48px;
        
        @media screen and (max-width: 1350px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 720px) {
          grid-template-columns: repeat(1, 1fr);
        }*/

        .person {
          position: relative;
          display: inline-flex !important;
          justify-content: center;
          height: 300px;
          padding-bottom: 24px;
          width: 100%;
          .name {
            position: absolute;
            width: 80%;
            left: 10%;
            background: rgba(28,26,48,0.975);
            color: white;
            padding: 16px;
            box-sizing: border-box;
            bottom: 0;
            user-select: none;
            clip-path: polygon(0 0, 100% 0, 100% 89%, 0 100%);

            p:first-child {
              font-size: 24px;
            }

            p:last-child {
              text-transform: uppercase;
              font-weight: 700;
            }
          }

          img {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 89%);
            aspect-ratio : 1 / 1;
            object-fit: cover;
            object-position: bottom;
            -webkit-user-drag: none;
            user-select: none;
            transition: transform 0.3s;
            height: 100%;
            width: 280px;
            
            &:hover {
                transform: scale(1.05);
            }
          }
        }
      }
  }
`
