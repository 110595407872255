import React, { Component } from "react"
import StyledNews from "./styled/StyledNews"

export default class extends Component {
    render() {
        return <StyledNews>
            <div className="container">
                <div className="title">
                    <h3>Neuigkeiten. Updates. Smalltalk.</h3>
                    <h2>Aktuelle Nachrichten</h2>
                </div>
                {/*<div className="news">
                    <div className="item">
                        <div className="date">Heute</div>
                        <img src="https://www.facility-manager.de/wp-content/uploads/2020/07/CAFM-Messe2020-350.jpg" />
                        <div className="title">CAFM-Messe 2023 - Count on us!</div>
                        <div className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
                        <div className="more"><i className="fas fa-arrow-right" /> Weiterlesen</div>
                    </div>
                    <div className="item">
                        <div className="date">14.04.2023</div>
                        <img src="https://scontent-dus1-1.xx.fbcdn.net/v/t39.30808-6/278377798_341943477914826_8807786910220172500_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=a26aad&_nc_ohc=WKYWtb3_KyoAX9XVPf5&_nc_ht=scontent-dus1-1.xx&oh=00_AfAV-TeIaO7N_JD9sqf1nExKtVyvYIRK-Z3GWA7Ygy5-uw&oe=6450F779" />
                        <div className="title">CP Version 23.1.19 veröffentlicht</div>
                        <div className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
                        <div className="more"><i className="fas fa-arrow-right" /> Weiterlesen</div>
                    </div>
                    <div className="item">
                        <div className="date">01.04.2023</div>
                        <img src="https://unsplash.com/photos/SoT4-mZhyhE/download?ixid=MnwxMjA3fDB8MXxzZWFyY2h8MTR8fG1vbmV5fGRlfDB8fHx8MTY4MjU3MDQ4MQ&force=true" />
                        <div className="title">Rekordquartal Q1/23</div>
                        <div className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</div>
                        <div className="more"><i className="fas fa-arrow-right" /> Weiterlesen</div>
                    </div>
                </div>*/}
                <ul className="juicer-feed" data-feed-id="scanmetrixfm" data-per="15"></ul>
            </div>
        </StyledNews>
    }
}
