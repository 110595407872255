import React, { Component } from "react";
import Footer from "../Start/modules/Footer";
import Header from "../Start/modules/SmallHeader";
import Navigation from "../Start/modules/Navigation";
import SuccessStoriesWrapper from "./modules/Content";

export default class SuccessStories extends Component {
  render() {
    return (
      <>
        <Navigation />
        <Header
          title="Erfolgsgeschichten."
          subTitle="Wie konnten Kunden profitieren"
          image="/img/successstories.jpg"
        />
        <SuccessStoriesWrapper>
        </SuccessStoriesWrapper>
        <Footer />
      </>
    );
  }
}
