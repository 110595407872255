import styled from "styled-components"

export default styled.nav`
    //for sticky navbar with black background
    position: fixed;
    z-index: 50;
    top: 0;

    //for normal css
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    background-color: ${props => props.offset ? "#20242b" : "transparent"};
    border-bottom: ${props => props.offset ? "4px solid #3b97d3" : "transparent"};
    transition: all 0.3s;
  
    .container {
      display: flex;
      justify-content: space-between;
      transition: all 0.3s;
      align-items: center;
      padding: 16px 64px;
      padding-top: ${props => props.offset ? "16px" : "40px"};
      box-sizing: border-box;
      max-width: 1500px;
      width: 100%;
    }
    
   a {
        color: inherit;
        text-decoration: none;
    }
  
    .logoContainer {
        height: 40px;
    }
  
    .logo {
      height: 40px;
      user-select: none;
      -webkit-user-drag: none;
      
      @media screen and (max-width: 768px) {
          width: 230px;
        }
    }
  
    .hamburger {
        display: none;
        color: white;
        font-size: 30px;
        cursor: pointer;
        transition: all 0.3s;
        
        &:hover {
            opacity: 0.5;
        }
      
      @media screen and (max-width: 1200px) {
        display: block;
      }
    }

    // only above was changed

    ul {
      user-select: none;
      z-index: 10;

      > div {
        display: grid;
        grid-auto-flow: column;
      }

      @media screen and (max-width: 1200px) {
        display: none;
      }

      .li {
        font-weight: 600;
        list-style-type: none;
        color: white;
        padding: 0 16px;
        cursor: pointer;
        font-size: 18px;
        position: relative;

        .subItemsMenu {
          position: absolute;
          top: 16px;
          padding-top: 32px;
        }

        .subItems {
          position: relative;
          display: grid;
          grid-auto-flow: row;
        }

        .subItemsTop {
          background: white;
          border-radius: 5px;
          padding: 16px;
          color: black;
          box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.15);
          cursor: default;
          overflow: hidden;
        }

        .underline {
          width: 32px;
          height: 3px;
          background: #3b97d3;
          position: absolute;
          bottom: -10px;
        }

        i {
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }
`


