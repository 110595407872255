import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  background-image: linear-gradient(90deg, rgba(28, 26, 48, 0.9), rgba(28, 26, 48, 0.9)), url(${props => props.image});
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  justify-content: center;
  padding: 128px 0;
  z-index: 5;
  position: relative;
 
  
  .container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    height: 100%;
    position: relative;
    user-select: none;
    

    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
    h2 {
        color: #3b97d3;
        padding-top: 48px;
        font-size: 32px;
    }
    
    h1 {
        color: white;
        font-size: 64px;
    }
  }
`
