import React from 'react'
import { useQuery, gql } from "@apollo/client";
import { Link } from 'react-router-dom'; // Import Link
import "./styled/StyledHomePage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faUserGraduate, faIdBadge} from '@fortawesome/free-solid-svg-icons';



const ASSETS_QUERY = gql`
  query Assets {
    blogposts(orderBy: date_DESC, first: 3) {
      id
      color{
        hex
      }
      title
      description
      icon
      shortDescription
      date
    }
  }
`;

const Index = () => {

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = String(originalDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // const { data, loading, error } = useQuery(ASSETS_QUERY, {
  //   uri: "https://api-eu-central-1.hygraph.com/v2/ckc0kun7m08ci01xl4h8500rq/master",
  // });

  const { data, loading, error } = useQuery(ASSETS_QUERY);
  
  if (loading) return "Loading...";
  if (error) return <pre>{error.message}</pre>;

  // const limitedData = data.blogposts.slice(0, 3);

  // single card
  // const filteredData = data.blogposts.filter((_, index) => index !== 0 && index !== 3 && index !== 5 && index !== 6 && index !== 7 && index !== 8  && index !== 9 && index !== 2 && index !== 4);

  // ALL CARDS except 1st and 4th
  // const filteredData = data.blogposts.filter((_, index) => index !== 0 && index !== 3 );

  // card 2 ,4 and 5 is displayed
  const filteredData = data.blogposts


  return (
    <div>
    
    <section className='general' >

    <div className='container-old' >
       <div className="title-main">
          <h1 className='h1' >Aktuelle Blogposts</h1>
          <p className='p' >
              Erfahre mehr zu technischem Facility Management, <br /> Gebäudemanagement, unseren Produkten und vielem mehr.
          </p>
      </div>
      
     <section className='services1'>
        {filteredData.map((blogpost) => (
          <div className='service1' key={blogpost.id}>
          <Link to={`/blog/${blogpost.id}`} className="link-style">
            <div className='top'>
            <p className='icons' ><i className={`fa-duotone fa-${blogpost.icon}`} /></p>
            <p className='title1'>{blogpost.title}</p>
            <p className='shortdescription' > {blogpost.shortDescription}</p>
            </div>

            <div className='bottom'>
            <p className='date'>{formatDate(blogpost.date)}</p>
            <button className="more">Weiterlesen <i className="arrow fas fa-arrow-right" /></button>
            </div>
            </Link>
          </div>
        ))}
      </section>
      
    </div>

    </section>

    </div>
  )
}

export default Index

     {/* <hr /> */}



// import React from 'react'
// import { useQuery, gql } from "@apollo/client";

// const ASSETS_QUERY = gql`
//   query Assets {
//     blogposts {
//       id
//       color{hex}
//       description
//       date
//       icon
//       locale
//       publishedAt
//       shortDescription
//       stage
//       title
//       updatedAt
//       createdAt
//     }
//   }
// `;

// const Index = () => {

//   // const { data, loading, error } = useQuery(ASSETS_QUERY, {
//   //   uri: "https://api-eu-central-1.hygraph.com/v2/ckc0kun7m08ci01xl4h8500rq/master",
//   // });

//   const { data, loading, error } = useQuery(ASSETS_QUERY);
  
//   if (loading) return "Loading...";
//   if (error) return <pre>{error.message}</pre>;

//   // Filtering out 1st and 4th response the data due to lack of description
//   // const limitedData = data.blogposts.slice(0, 9);
//   const filteredData = data.blogposts.filter((_, index) => index !== 0 && index !== 3);

//   return (
//     <div>
//       <h1>Blog Posts</h1>
//       <ul>
//         {filteredData.map((blogpost) => (
//           <li key={blogpost.id}>
//             <h2>{blogpost.title}</h2>
//             <p>Description: {blogpost.description}</p>
//             <p>Color: {blogpost.color.hex}</p>
//             <p>Date: {blogpost.date}</p>
//             <p>Icon: {blogpost.icon}</p>
            //  {blogpost.icon === 'hands-helping' && <FontAwesomeIcon icon={faHandsHelping} />}
              //  {blogpost.icon === 'birthday-cake' && <FontAwesomeIcon icon={faBirthdayCake} />}
              //  {blogpost.icon === 'user-graduate' && <FontAwesomeIcon icon={faUserGraduate} />}
//             <p>Locale: {blogpost.locale}</p>
//             <p>Published Date: {blogpost.publishedAt}</p>
//             <p>Short Description: {blogpost.shortDescription}</p>
//             <p>Stage: {blogpost.stage}</p>
//             <p>Updated At: {blogpost.updatedAt}</p>
//             <p>Created At: {blogpost.createdAt}</p>
//           </li>
//         ))}
//       </ul>
//     </div>
//   )
// }

// export default Index

    