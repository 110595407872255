import styled from "styled-components"

export default styled.div`
    background: #20242b;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 96px 0;
    color: white;
    position: relative;
  
    .zia {
        margin-top: 16px;
        filter: grayscale(100%);
        opacity: 0.5;
        transition: all 0.3s;
        max-height: 96px;
      
        &:hover {
            filter: none;
            opacity: 1;
        }
    }
  
    .up {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 100%;
        font-size: 24px;
        background: #20242b;
        color: white;
        z-index: 1;
        left: 32px;
        bottom: 32px;
        transition: all 0.3s;
        position: fixed;
      
        &:hover {
            background: #3b97d3;
        }

        @media screen and (max-width: 768px) {
            top: 97%;
            left: 12%;
        }
    }

    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 64px;
        max-width: 1500px;
      
        .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            padding-top: 24px;
            margin-top: 24px;
            user-select: none;
            border-top: 1px solid rgba(255, 255, 255, 0.15);
          
            @media screen and (max-width: 900px) {
                flex-wrap: wrap;
              
                p {
                    width: 100%;
                    margin-bottom: 16px;
                }
              
                .love {
                    padding-right: 24px;
                }
            }
          
            .love {
                font-size: 16px;
              
                .fa-heart {
                    color: #3b97d3;
                }
            }
          
            p {
                display: flex;
                align-items: center;
                opacity: 0.75;
              
                i {
                    margin-right: 8px;
                }
            }
          
            .social {
                display: grid;
                grid-auto-flow: column;
                grid-gap: 16px;
                
                a {
                    text-decoration: none;
                    color: white;
                    font-size: 20px;
                    opacity: 0.5;
                    transition: all 0.3s;
                  
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
      
        .grid {
            display: grid;
            grid-gap: 80px;
            grid-template-columns: 2fr 1.5fr 1fr 0.5fr;
            width: 100%;
          
            @media screen and (max-width: 1280px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
          
              @media screen and (max-width: 980px) {
                grid-gap: 32px;
              }
          
          @media screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
            grid-gap: 64px;
          }
            
            .item {
                &:first-child {

                  @media screen and (max-width: 1280px) {
                    grid-column-start: 1;
                    grid-column-end: 4;
                  }
                  
                  @media screen and (max-width: 900px) {
                    grid-column-start: 1;
                    grid-column-end: 3;
                  }
                }
              
                &:nth-child(3) {
                  @media screen and (max-width: 900px) {
                    text-align: right;
                  }
                }
                &:last-child {
                  text-align: right;
                  @media screen and (max-width: 900px) {
                    text-align: center;
                    grid-column-start: 1;
                    grid-column-end: 3;
                  }
                }
                
                .logo {
                    margin-top: 32px;
                    user-select: none;
                    display: block;
                  
                    img {
                        height: 40px;
                        -webkit-user-drag: none;
                    }
                }
              
                .description {
                    line-height: 32px;
                    opacity: 0.75;
                    font-size: 18px;
                    user-select: none;
                }
              
                .title {
                    height: 32px;
                    margin-bottom: 16px;
                    font-size: 24px;
                    font-weight: 600;
                    text-transform: uppercase;
                    user-select: none;
                }
              
                ul {
                    li {
                        user-select: none;
                        list-style-type: none;
                        line-height: 40px;
                        font-size: 20px;
                        
                        a {
                            color: white;
                            text-decoration: none;
                            opacity: 0.5;
                            transition: all 0.3s;
                            cursor: pointer;
                            font-weight: 600;
                            
                            &:hover {
                                opacity: 1;
                                color: #3b97d3;
                            }
                        }
                    }
                }
            }
        }
    }
`
