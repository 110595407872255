import React, { useState } from "react";
import styled, { css } from "styled-components";
import StyledDownloadButton from "./StyledDownloadButton";
import ModalComponent from "./StyledModalComponent";

const successStories = [
  {
    category: "Einzelhandel",
    logo: "/img/customer-logos/burgerking.svg",
    quote: (
      <span>
        Scanmetrix hat es uns ermöglicht, den{" "}
        <span style={{ color: "#3b97d3" }}>Lifecycle</span> eines{" "}
        <span style={{ color: "#3b97d3" }}>
          Restaurants vollständig abzubilden
        </span>
        . Alle festgestellten Mängel, Bestände und Ansprechpartner sind in einer{" "}
        <span style={{ color: "#3b97d3" }}>zentralen Plattform</span> historisch{" "}
        <span style={{ color: "#3b97d3" }}>nachvollziehbar gespeichert</span>.
        Dadurch können wir alle{" "}
        <span style={{ color: "#3b97d3" }}>relevanten Funktionen</span> für den
        Bau und die tägliche Arbeit,{" "}
        <span style={{ color: "#3b97d3" }}>plattformübergreifend</span> nutzen.
      </span>
    ),
    stats: [
      { icon: "building", text: "150+ Liegenschaften" },
      { icon: "cart-shopping", text: "700+ Bestellungen" },
      { icon: "ticket", text: "2.000+ Tickets" },
    ],
    className: "burger-king",
  },
  {
    category: "Handwerksbetrieb",
    logo: "/img/customer-logos/crafty.png",
    quote: (
      <span>
        Scanmetrix hat unsere{" "}
        <span style={{ color: "#3b97d3" }}>Excel-basierte Verwaltung</span>{" "}
        durch eine{" "}
        <span style={{ color: "#3b97d3" }}>zentrale digitale Plattform</span>{" "}
        ersetzt, die alle Niederlassungen und Verträge live abbildet. Dadurch
        haben wir jetzt einen umfassenden Überblick und{" "}
        <span style={{ color: "#3b97d3" }}>erfüllen</span> unsere{" "}
        <span style={{ color: "#3b97d3" }}>Betreiberpflichten</span> effizient.
        Dies ermöglicht uns eine lückenlose Nachverfolgung.
      </span>
    ),
    stats: [
      { icon: "house", text: "60+ Liegenschaften" },
      { icon: "magnifying-glass", text: "650+ mtl. Begehungen" },
      { icon: "gear", text: "8.000+ TGA erfasst" },
    ],
    className: "crafty",
  },
];

const SuccessStoriesContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 100px 0;
  font-size: 20px;
`;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;

  section {
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0;
    gap: 100px;
    background-color: #f4f4f4;

    .left-container {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      max-width: 800px;
      gap: 15px;
      line-height: 40px;

      h1 {
        font-size: 50px;
        text-align: left;
      }

      h3 {
        font-size: 20px;
        text-align: left;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .stats-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 800px;
      flex-shrink: 0;
      gap: 25px;

      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%; 
        gap: 20px;
      }

      .icon {
        width: 40px; 
        text-align: center; 
        margin-top: 0.1px;
      }

      span {
        font-size: 35px;
        font-weight: 700;
        flex: 1;
      }
    }

    @media screen and (max-width: 1280px) {
      gap: 30px;

      .left-container {
        max-width: 500px;
      }

      .stat-container {
        max-width: 800px;
      }
    }

    @media screen and (max-width: 700px) {
      .left-container {
        h1 {
          font-size: 35px;
        }
      }
    }
  }
`;

const Logo = styled.img`
  ${(props) =>
    props.src.includes("burgerking")
      ? css`
          height: 100px;
          width: 100px;

          @media screen and (max-width: 900px) {
            margin-left: 280px;
            margin-bottom: -55px;
            height: 75px;
            width: 75px;
          }

          @media screen and (max-width: 700px) {
            margin-left: 195px;
          }
        `
      : props.src.includes("crafty")
      ? css`
          height: 40px;
          width: 200px;

          @media screen and (max-width: 900px) {
            margin-left: 390px;
            margin-bottom: -50px;
          }

          @media screen and (max-width: 700px) {
            height: 40px;
            width: 150px;
            margin-bottom: -45px;
            margin-left: 280px;
          }
        `
      : css`
          height: auto;
          width: auto;
        `}
`;

const SuccessStoriesPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <SuccessStoriesContainer>
      <Container>
        <h3
          style={{
            paddingBottom: "15px",
            fontSize: "50px",
            color: "#3b97d3",
            textAlign: "center",
          }}
        >
          Unsere Kunden
        </h3>
        {successStories.map((story, index) => (
          <section key={index} className={story.className || ""}>
            <main className="left-container">
              <Logo src={story.logo} />
              <h1>{story.category}</h1>
              <h3>{story.quote}</h3>
            </main>

            <main className={`stats-container ${story.className}`}>
              {story.stats.map((stat, idx) => (
                <div key={idx}>
                  <i
                    className={`fa-duotone fa-${stat.icon} icon`}
                    style={{ fontSize: "40px", color: "#3b97d3" }}
                  />
                  <span>{stat.text}</span>
                </div>
              ))}
              <StyledDownloadButton
                className={story.className}
                onClick={openModal}
              >
                Erfolgsgeschichte herunterladen
              </StyledDownloadButton>
            </main>
          </section>
        ))}
      </Container>
      <ModalComponent isOpen={modalIsOpen} onRequestClose={closeModal} />
    </SuccessStoriesContainer>
  );
};

export default SuccessStoriesPage;

