import styled from "styled-components";

export default styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(90deg, rgba(28, 26, 48, 0.9), rgb(28 26 48 / 68%)), url(/img/start/header.jpg);
    background-position: center 120%;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    padding-top: 96px;
    

  @media screen and (max-width: 1200px) {
    background-attachment: unset;  
  }

  @keyframes scroll {
    0% { transform: translateX(100%); }
    100% { transform: translateX(calc(-550px * 7))}
  }
  
  

  
  
    .container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 64px;
        max-width: 1500px;
        height: 100%;
        position: relative;
        z-index: 3;



      @media screen and (max-width: 1024px) {
        padding: 0 48px;
      }

        .blur {
            backdrop-filter: blur(8px);
            background-color: rgba(255, 255, 255, 0.1);
            width: fit-content;
            padding: 80px;
            height: fit-content;
            border-radius: 10px;
            margin-top: 140px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 240px;


          @media screen and (max-width: 800px) {
            padding: 40px;
            
            h1 {
                font-size: 70px;
            }
          }
          
          @media screen and (max-width: 650px) {
            padding: 0;
            backdrop-filter: none;
            background-color: transparent;
            border: none;

            h1 {
              font-size: 74px;
            }
          }
        }
      
        .buttons {
            display: grid;
            grid-gap: 16px;
            grid-auto-flow: column;
            width: min-content;
            margin-top: 40px;

          @media screen and (max-width: 650px) {
            grid-auto-flow: row;
            width: 100%;
            
            button {
                width: 100% !important;
                justify-content: center;
                height: 70px;
                font-size: 20px;
            }
          }
        }
      
        button {
            white-space: nowrap;
            height: 56px;
            padding: 0 24px;
            display: flex;
            align-items: center;
            outline: 0;
            border: none;
            color: white;
            text-transform: uppercase;
            font-weight: 700;
            font-family: inherit;
            cursor: pointer;
            user-select: none;
            font-size: 18px;
            transition: opacity 0.3s;

            &:hover {
                opacity: 0.75;
            }
          
            i {
                margin-right: 12px;
            }
          
            &:first-child {
                background: #3b97d3;
            }
          
            &:last-child {
                background: transparent;
                border: 2px solid white;
            }
        }
      
        h1 {
            font-weight: 700;
            font-size: 84px;
            text-transform: uppercase;
            color: white;
            user-select: none;
            line-height: 84px;

            span {
                color: #3b97d3;
                text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
            }
        }
      
        h2 {
            user-select: none;
            color: white;
            font-weight: 400;
            font-size: 30px;
            margin-top: 24px;
        }
    }
  
    .triangle {
        height: 0;
        width: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        border-top: 600px solid transparent;
        border-right: 600px solid #3b97d3;
        opacity: 0.5;
        z-index: 1;
    }

  .triangle2 {
    height: 0;
    width: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    border-top: 780px solid transparent;
    border-right: 780px solid #3b97d3;
    opacity: 0.1;
    z-index: 2;
  }
  .upper{
    
    user-select: none;
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    padding: 4px;
    color: white;
    z-index: 3; //ändern wenn der balken hinter dem dreieck sein soll
    width: 99.63%;
	  height: 100px;

    .newsSlider{
       
      display: flex;
      height: inherit;
      grid-gap: 150px;
      padding: 4px;
      //animation: scroll 30s linear infinite;
      width: 100%;
      
      .news-container{
        display: flex;
        justify-content: center; 
        align-items: center;
         .add{
           display: flex;
           justify-content: center; 
           flex-direction: column;
         }
      }
   }
}
  
`
